import React, { useRef, useEffect, useState } from "react"
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Row,
    Col,
    Container,
    Button,
    ButtonGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
  } from "reactstrap"
import {
  GoogleMap,
  Marker,
  DirectionsRenderer,
  useJsApiLoader,
  Autocomplete,
} from "@react-google-maps/api"
import { FaLocationArrow, FaTimes } from "react-icons/fa"
import {
  GetDrivers,
  GetProviderById,
  GetProviders,
} from "helpers/backend_helper"

const center = { lat: 9.007923, lng: 38.767821 }

function CreateMap({ setpickup, setdropoff, set_distance, set_duration }) {
  // const { isLoaded } = useJsApiLoader({
  //   googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  //   libraries: ["places"],
  // })

  const [map, setMap] = useState(null)
  const [directionsResponse, setDirectionsResponse] = useState(null)
  const [distance, setDistance] = useState("")
  const [duration, setDuration] = useState("")

  const originRef = useRef()
  const destinationRef = useRef()
  const originAutocompleteRef = useRef(null)
  const destinationAutocompleteRef = useRef(null)

  const calculateRoute = async () => {
    if (!originRef.current.value || !destinationRef.current.value) return

    const directionsService = new window.google.maps.DirectionsService()
    const results = await directionsService.route({
      origin: originRef.current.value,
      destination: destinationRef.current.value,
      travelMode: window.google.maps.TravelMode.DRIVING,
    })

    setDirectionsResponse(results)
    setDistance(results.routes[0].legs[0].distance.text)
    setDuration(results.routes[0].legs[0].duration.text)

    const originPlace = originAutocompleteRef.current.getPlace()
    const destinationPlace = destinationAutocompleteRef.current.getPlace()

    function higher_lever_call() {
      //for the passed in props
      if (set_distance != null && set_duration != null) {
        set_distance(results.routes[0].legs[0].distance.text)
        set_duration(results.routes[0].legs[0].duration.text)
      }
      if (originPlace && originPlace.geometry && setpickup != null) {
        const { lat, lng } = originPlace.geometry.location
        setpickup({
          address: originRef.current.value,
          latitude: lat(),
          longitude: lng(),
        })
        console.log({
          address: originRef.current.value,
          latitude: lat(),
          longitude: lng(),
        })
      }

      if (destinationPlace && destinationPlace.geometry && setdropoff != null) {
        const { lat, lng } = destinationPlace.geometry.location
        setdropoff({
          address: destinationRef.current.value,
          latitude: lat(),
          longitude: lng(),
        })
        console.log({
          address: destinationRef.current.value,
          latitude: lat(),
          longitude: lng(),
        })
      }
    }
    higher_lever_call()
  }

  const clearRoute = () => {
    setDirectionsResponse(null)
    setDistance("")
    setDuration("")
    originRef.current.value = ""
    destinationRef.current.value = ""
    if (!(!setpickup || !setdropoff || !set_distance || !set_duration)) {
      setpickup(null)
      setdropoff(null)
      set_distance(null)
      set_duration(null)
    }
  }

  // if (!isLoaded) {
  //   return <p>Loading...</p>
  // }

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Row className="vh-60">
          <div className="col-12 position-relative">
            <GoogleMap
              center={center}
              zoom={15}
              mapContainerStyle={{
                width: "100%",
                height: "600px",
                marginBottom: "15px",
              }}
              options={{
                zoomControl: false,
                streetViewControl: false,
                mapTypeControl: false,
                fullscreenControl: false,
              }}
              onLoad={map => setMap(map)}
            >
              {/* <Marker position={center} /> */}
              {directionsResponse && (
                <DirectionsRenderer directions={directionsResponse} />
              )}
            </GoogleMap>
          </div>
          <div className="col d-flex align-items-center justify-content-center">
            <div className="container">
              <Row className="mb-3">
                <Col md="5">
                  <InputGroup className="w-100">
                    <Autocomplete
                      className="w-100"
                      onLoad={autocomplete =>
                        (originAutocompleteRef.current = autocomplete)
                      }
                      onPlaceChanged={() => {
                        const place = originAutocompleteRef.current.getPlace()
                        if (place.geometry) {
                          const { lat, lng } = place.geometry.location
                          setpickup({
                            address: originRef.current.value,
                            latitude: lat(),
                            longitude: lng(),
                          })
                        }
                      }}
                    >
                      <input
                        type="text"
                        className="form-control h-100"
                        placeholder="Origin"
                        ref={originRef}
                      />
                    </Autocomplete>
                  </InputGroup>
                </Col>
                <Col md="5">
                  <InputGroup className="w-100">
                    <Autocomplete
                      className="w-100"
                      onLoad={autocomplete =>
                        (destinationAutocompleteRef.current = autocomplete)
                      }
                      onPlaceChanged={() => {
                        const place =
                          destinationAutocompleteRef.current.getPlace()
                        if (place.geometry) {
                          const { lat, lng } = place.geometry.location
                          setdropoff({
                            address: destinationRef.current.value,
                            latitude: lat(),
                            longitude: lng(),
                          })
                        }
                      }}
                    >
                      <input
                        type="text"
                        className="form-control w-100"
                        placeholder="Destination"
                        ref={destinationRef}
                      />
                    </Autocomplete>
                  </InputGroup>
                </Col>

                <Col md="2">
                  <ButtonGroup>
                    <Button color="primary" onClick={calculateRoute}>
                      Calculate Route
                    </Button>
                    <Button color="danger" onClick={clearRoute}>
                      <FaTimes />
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
              <Row className="mb-3">
                <div className="col">
                  <p>Distance: {distance}</p>
                </div>
                <div className="col">
                  <p>Duration: {duration}</p>
                </div>
                <div className="col d-flex align-items-center">
                  <button
                    className="btn btn-light"
                    onClick={() => {
                      map.panTo(center)
                      map.setZoom(15)
                    }}
                  >
                    <FaLocationArrow />
                  </button>
                </div>
              </Row>
            </div>
          </div>
        </Row>
      </div>
    </div>
  )
}

export default CreateMap
