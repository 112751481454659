import React from "react";

export function ToggleFullscreen() {
  function toggleFullscreen() {
    if (!document.fullscreenElement &&
            /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }
  return <div className="dropdown d-none d-lg-inline-block">
    <button
      type="button"
      onClick={() => {
        toggleFullscreen();
      }}
      className="btn header-item noti-icon waves-effect"
      data-toggle="fullscreen"
    >
      <i className="mdi mdi-fullscreen"></i>
    </button>
  </div>;
}
