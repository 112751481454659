import axios from "axios"
import { error } from "toastr"
// import accessToken from "./jwt-token-access/accessToken"
// import { ColumnToggle } from "react-bootstrap-table2-toolkit"
// import { ColumnToggle } from  "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
//pass new generated access token here
const token = localStorage.getItem("token") || ""

//apply base url for axios
const API_URL = "https://ride-haling-backend.onrender.com/api/"
// const API_URL = "http://localhost:3001/api/"
const axiosApi = axios.create({
  baseURL: API_URL,
})

axiosApi.defaults.headers.common["Authorization"] = token

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}) {
  return (
    await axiosApi
      .post(url, { ...data }, { ...config })
      .then(response => {
        // console.log(response)
        return response
      })
      //  this was creating problems so i commented it out
      // .then(response => {
      //   return response
      // })
      .catch(e => {
        console.log(e)
        throw e
      })
  )
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response)
}
