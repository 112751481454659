// import PropTypes from 'prop-types';
// import React, { useState, useEffect } from "react";
// import {
//   Container,
//   Row,
//   Col,
//   Card,
//   Alert,
//   CardBody,
//   Button,
//   Form,
//   FormFeedback,
//   Label,
//   Input
// } from "reactstrap";

// // Formik validation
// import * as Yup from "yup";
// import { useFormik } from "formik";

// // Redux
// import { connect, useDispatch } from "react-redux";
// import withRouter from 'components/Common/withRouter';

// Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"
import avatar from "../../assets/images/users/user-4.jpg"
// // actions
// import { edit, resetFlag } from "../../store/actions";

// const User = props => {
//   const dispatch = useDispatch();

//   const [email, setemail] = useState("");
//   const [name, setname] = useState("");
//   const [idx, setidx] = useState(1);

//   useEffect(() => {
//     if (localStorage.getItem("authUser")) {
//       const obj = JSON.parse(localStorage.getItem("authUser"));
//       if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
//         setname(obj.displayName);
//         setemail(obj.email);
//         setidx(obj.uid || 1);
//       } else if (
//         process.env.REACT_APP_DEFAULTAUTH === "fake" ||
//         process.env.REACT_APP_DEFAULTAUTH === "jwt"
//       ) {
//         setname(obj.username);
//         setemail(obj.email);
//         setidx(obj.uid || 1);
//       }
//       setTimeout(() => {
//         props.resetFlag();
//       }, 3000);
//     }
//   }, [props.success]);

//   const validation = useFormik({
//     // enableReinitialize : use this flag when initial values needs to be changed
//     enableReinitialize: true,

//     initialValues: {
//       username: name || '',
//       idx: idx || '',
//     },
//     validationSchema: Yup.object({
//       username: Yup.string().required("Please Enter Your User Name"),
//     }),
//     onSubmit: (values) => {
//       dispatch(edit(values));
//     }
//   });

//   document.title = " | hearts - React Admin & Dashboard  ";
//   return (
//     <React.Fragment>
//       <div className="page-content">
//         <Container fluid>
//           {/* Render Breadcrumb */}
//           <Breadcrumb title="hearts" breadcrumbItem="" />

//           <Row>
//             <Col lg="12">
//               {props.error && props.error ? (
//                 <Alert color="danger">{props.error}</Alert>
//               ) : null}
//               {props.success ? (
//                 <Alert color="success">{props.success}</Alert>
//               ) : null}

//               <Card>
//                 <CardBody>
//                   <div className="d-flex">
//                     <div className="mx-3">
//                       <img
//                         src={avatar}
//                         alt=""
//                         className="avatar-md rounded-circle img-thumbnail"
//                       />
//                     </div>
//                     <div className="align-self-center flex-1">
//                       <div className="text-muted">
//                         <h5>{name}</h5>
//                         <p className="mb-1">{email}</p>
//                         <p className="mb-0">Id no: #{idx}</p>
//                       </div>
//                     </div>
//                   </div>
//                 </CardBody>
//               </Card>
//             </Col>
//           </Row>

//           <h4 className="card-title mb-4">Change User Name</h4>

//           <Card>
//             <CardBody>

//               <Form
//                 className="form-horizontal"
//                 onSubmit={(e) => {
//                   e.preventDefault();
//                   validation.handleSubmit();
//                   return false;
//                 }}
//               >
//                 <div className="form-group">
//                   <Label className="form-label">User Name</Label>
//                   <Input
//                     name="username"
//                     className="form-control"
//                     placeholder="Enter User Name"
//                     type="text"
//                     onChange={validation.handleChange}
//                     onBlur={validation.handleBlur}
//                     value={validation.values.username || ""}
//                     invalid={
//                       validation.touched.username && validation.errors.username ? true : false
//                     }
//                   />
//                   {validation.touched.username && validation.errors.username ? (
//                     <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
//                   ) : null}
//                   <Input name="idx" value={idx} type="hidden" />
//                 </div>
//                 <div className="text-center mt-4">
//                   <Button type="submit" color="danger">
//                     Edit User Name
//                   </Button>
//                 </div>
//               </Form>
//             </CardBody>
//           </Card>
//         </Container>
//       </div>
//     </React.Fragment>
//   );
// };

// User.propTypes = {
//   edit: PropTypes.func,
//   error: PropTypes.any,
//   success: PropTypes.any
// };

// // const mapStatetoProps = state => {
// //   const { error, success } = state.;
// //   return { error, success };
// // };

// export default withRouter(
//   connect(mapStatetoProps, { edit, resetFlag })(User)
// );

import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Card,
  CardBody,
  CardTitle,
  CardText,
  CardLink,
} from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import user3 from "../../assets/images/users/user-3.jpg"
import { post, del, get, put } from "../../helpers/api_helper"
import React, { useState, useEffect } from "react"
import { toast } from "react-toastify"
import { Hidden } from "@mui/material"
import Dropzone from "react-dropzone"

import { ref, uploadBytes, getDownloadURL } from "firebase/storage"
import { storage } from "../../helpers/firebase_storage.js"
import { LoadingButton, LoadingPage } from "pages/Utility/loading"
import { set } from "lodash"
import { GetLoggedInUser } from "helpers/backend_helper"

function User() {
  const [user, setUser] = useState(GetLoggedInUser)
  const [id, setId] = useState(user?.id||null)
  const navigate = useNavigate()
  const [managerData, setManagerData] = useState(null)
  const [isEditing, setIsEditing] = useState(false)
  const [isClicked, setIsClicked] = useState(false)
  const [editManagerDetail, setEditManagerDetail] = useState(false)
  const [editedManagerData, setEditedManagerData] = useState(null)

  const handleAcceptedFiles = async acceptedFiles => {
    if (acceptedFiles.length > 0) {
      try{ 
        const file = acceptedFiles[0]
          var formData = new FormData();
            formData.append("files", file)
    
          // Log the contents of the FormData
          for (const [key, value] of formData.entries()) {
          }
          
    
          var response = await post("/upload", ...formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          const fileUrl = response.data.fileUrls[file.name];
          setEditedManagerData((prevData) => ({
            ...prevData,
            photo: fileUrl, 
          }));
    
          } catch (error) {
            toast.error("Error uploading file");
          }
    }
  }

  useEffect(() => {
    const fetchManagerData = async () => {
      try {
        const data = await get(`/managers/${id}`)
        setManagerData(data)
        setEditedManagerData(data)
        console.log("/////////////////////////////")
        console.log(data)
      } catch (error) {
        console.error("Error fetching Managers data:", error)
        toast.error("Error fetching Managers data")
      }
    }
    if (id != null) {
      fetchManagerData()
    }
  }, [id])
  const handleSave = async () => {
    try {
      setIsClicked(true)
      setEditedManagerData(editedManagerData)
      setEditManagerDetail(false)
      await put(`/managers/${id}`, editedManagerData)
      toast.success("updated")
      setIsClicked(false)
    } catch (error) {
      toast.error(error)
      setIsClicked(false)
    }
  }

  if (!managerData) {
    return (<LoadingPage />)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="hearts" breadcrumbItem="" />

          <Row>
            <Col lg="12">
              {/* {props.error && props.error ? (
              <Alert color="danger">{props.error}</Alert>
            ) : null}
            {props.success ? (
              <Alert color="success">{props.success}</Alert>
            ) : null} */}

              <Card>
                <CardBody>
                  <div className="d-flex ">
                    <FormGroup style={{ marginRight: "10px" }}>
                      <Label for="image">Profile image</Label>
                      {editManagerDetail ? (
                        <Dropzone onDrop={handleAcceptedFiles}>
                          {({ getRootProps, getInputProps }) => (
                            <div
                              {...getRootProps()}
                              style={{
                                backgroundImage: `url(${
                                  editedManagerData?.photo ||
                                  managerData?.photo ||
                                  user3
                                })`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                height: "100px",
                                width: "100px",
                                borderRadius: "50%",
                                border: "2px solid #ccc",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <input {...getInputProps()} />
                              {editManagerDetail && (
                                <div
                                  style={{
                                    position: "relative",
                                    top: "50px",
                                    left: "50px",
                                    background: "rgba(255, 255, 255, 0.7)",
                                    borderRadius: "50%",
                                    padding: "5px",
                                  }}
                                >
                                  <i className="mdi mdi-cloud-upload display-6 text-muted"></i>
                                </div>
                              )}
                            </div>
                          )}
                        </Dropzone>
                      ) : (
                        <div
                          style={{
                            backgroundImage: `url(${
                              editedManagerData?.photo ||
                              managerData?.photo ||
                              user3
                            })`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            height: "100px",
                            width: "100px",
                            borderRadius: "50%",
                            border: "2px solid #ccc",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginRight: "10px",
                          }}
                        />
                      )}
                    </FormGroup>

                    <div className="align-self-center flex-1">
                      <div className="text-muted">
                        <h5>{managerData?.name}</h5>
                        <p className="mb-1">{managerData?.email}</p>
                        <p className="mb-0">Role: #{managerData?.role?.name}</p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <h4 className="card-title mb-4">Change User Information</h4>

          <Card>
            <CardBody>
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <div className="form-group mb-3">
                  <Label className="form-label">Name</Label>
                  <Input
                    className="form-control"
                    type="tel"
                    id="phone_number"
                    value={editedManagerData?.name}
                    onChange={e =>
                      setEditedManagerData({
                        ...editedManagerData,
                        name: e.target.value,
                      })
                    }
                    disabled={!editManagerDetail}
                  />
                  {/* {validation.touched.username && validation.errors.username ? (
                  <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                ) : null} */}
                  <Input name="idx" value={managerData?.idx} type="hidden" />
                </div>
                <div className="form-group  mb-3">
                  <Label className="form-label">Phone Number</Label>
                  <Input
                    className="form-control"
                    type="tel"
                    id="phone_number"
                    value={editedManagerData?.phone_number}
                    onChange={e =>
                      setEditedManagerData({
                        ...editedManagerData,
                        phone_number: e.target.value,
                      })
                    }
                    disabled={!editManagerDetail}
                  />
                  {/* {validation.touched.username && validation.errors.username ? (
                  <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                ) : null} */}
                  <Input name="idx" value={managerData?.idx} type="hidden" />
                </div>
                <div className="form-group  mb-3">
                  <Label className="form-label">User Name</Label>
                  <Input
                    className="form-control"
                    type="text"
                    id="phone_number"
                    value={editedManagerData?.username}
                    onChange={e =>
                      setEditedManagerData({
                        ...editedManagerData,
                        username: e.target.value,
                      })
                    }
                    disabled={!editManagerDetail}
                  />
                  {/* {validation.touched.username && validation.errors.username ? (
                  <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                ) : null} */}
                  <Input name="idx" value={managerData?.idx} type="hidden" />
                </div>
                <div className="form-group  mb-3">
                  <Label className="form-label">Email</Label>
                  <Input
                    className="form-control"
                    type="text"
                    id="phone_number"
                    value={editedManagerData?.email}
                    onChange={e =>
                      setEditedManagerData({
                        ...editedManagerData,
                        email: e.target.value,
                      })
                    }
                    disabled={!editManagerDetail}
                  />
                  {/* {validation.touched.username && validation.errors.username ? (
                  <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                ) : null} */}
                  <Input name="idx" value={managerData?.idx} type="hidden" />
                </div>
                <div className="form-group  mb-3">
                  <Link style={{ textDecoration: "none" , padding: "0.5rem", background: "lightgreen", borderRadius: "5px", color: "white"}} color="success" to={"/change-password"}>
                    Change Password
                    </Link>
                </div>
                <div className="text-center mt-4">
                  {isClicked  ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <LoadingButton />
                  </div>
                ) :editManagerDetail ? (
                    <div>
                      {" "}
                      <Button
                        onClick={() => setEditManagerDetail(false)}
                        color="danger"
                      >
                        Close
                      </Button>{" "}
                     <Button color="primary" onClick={handleSave}>
                        Save
                      </Button> 

                    </div>
                  ) : (
                    <Button
                      onClick={() => setEditManagerDetail(true)}
                      color="danger"
                    >
                      Edit
                    </Button>
                  )}
                </div>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default User
