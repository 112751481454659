import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, CardBody, Card, Container } from "reactstrap";
import { Link } from "react-router-dom";
import axios from "axios";
// import images
import logoSm from "../../assets/images/logo-sm.png";
import { post } from "helpers/api_helper";
import { toast } from "react-toastify";
import { LoadingButton } from "pages/Utility/loading";

const ForgetPasswordPage = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [showButton, setShowButton] = useState(true);
  async function handleReset(e) {
    e.preventDefault();
    try {
      setShowButton(false)
      await post('/auth/forgot-password', { username, phone_number: phoneNumber });
      localStorage.setItem('username', username);
      localStorage.setItem('phoneNumber', phoneNumber);
      toast.success('Otp sent successfully');
      navigate('/page-recoverpw');
    } catch (error) {
      console.error(error);
      setShowButton(true)
      toast.error('Failed to send otp');
    }
  }

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2"></i>
        </Link>
      </div>
      <div className="account-pages my-5 pt-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={4}>
              <Card className="overflow-hidden">
                <div className="bg-primary">
                  <div className="text-primary text-center p-4">
                    <h5 className="text-white font-size-20 p-2">Forget Password</h5>
                    <Link to="/index" className="logo logo-admin">
                      <img src={logoSm} height="24" alt="logo" />
                    </Link>
                  </div>
                </div>
                <CardBody className="p-4">
                  <div className="p-3">
                    <div className="alert alert-success mt-5" role="alert">
                      Enter your Username and Phone Number Otp will be sent to you!
                    </div>
                    <form className="mt-4" onSubmit={(e)=>handleReset(e)}>
                      <div className="mb-3">
                        <label className="form-label" htmlFor="username">Username</label>
                        <input
                          type="text"
                          className="form-control"
                          id="username"
                          placeholder="Enter username"
                          value={username}
                          onChange={(e) => setUsername(e.target.value)}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label" htmlFor="phonenumber">Phone Number</label>
                        <input
                          type="tel"
                          className="form-control"
                          id="phonenumber"
                          placeholder="Start with +251 ..."
                          value={phoneNumber}
                          onChange={(e) => setPhoneNumber(e.target.value)}
                        />
                      </div>
                      <Row className="mb-0">
                        <Col className="col-12 text-end">
                        {showButton
                            ? <button  className="btn btn-primary w-md waves-effect waves-light" type="submit"  >Send Otp</button>:
                            <LoadingButton />}
                        </Col>
                      </Row>
                    </form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Remember It ? <Link to="/pages-login" className="fw-medium text-primary">
                    Sign In here
                  </Link>
                </p>
                <p>
                  © {new Date().getFullYear()} hearts. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> by HEARTS
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ForgetPasswordPage;