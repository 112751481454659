import React, { useState, useEffect } from "react"

import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from "reactstrap"
import { Link } from "react-router-dom"
import user2 from "../../assets/images/users/user-2.jpg"
import user3 from "../../assets/images/users/user-3.jpg"

import Breadcrumbs from "../../components/Common/Breadcrumb"

import { post, del, get, put } from "../../helpers/api_helper"
import { GetLoggedInUser, GetReviews } from "helpers/backend_helper"
import ReactPaginate from "react-paginate"
import SkeletonRow from "../../components/Common/skeletonRow"
import ReviewCard from "../../components/Common/ReviewCard"
function Reviews() {
  const [reviews, setReview] = useState([]); // Set initial state to an empty array
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [itemsPerPage, setItemsPerPage] = useState(12)
  const [all, setAll] = useState(false)
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const authUser = GetLoggedInUser()
        if (authUser.role_id !== "1") {
          const response = await GetReviews({
            params: {
              page: currentPage,
              limit: itemsPerPage,
              all: all,
              providerId: authUser.provider_id,
            },
          })
          const { data, total_pages } = response
          setTotalPages(total_pages)
          setReview(data)
        } else {
          const response = await GetReviews({
            params: { page: currentPage, limit: itemsPerPage, all: all },
          })
          const { data, total_pages } = response
          setTotalPages(total_pages)
          setReview(data)
        }
      } catch (error) {
        console.error("Error fetching Reviews:", error)
      } finally {
        setLoading(false)
      }
    }

    fetchReviews()
  }, [currentPage, itemsPerPage])

  const handlePageChange = pageNumber => {
    setCurrentPage(pageNumber)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Row>
          <Col md="7">
            <Breadcrumbs maintitle="hearts" title="Reviews" />
          </Col>
          <Col md="5" className="align-self-end" />
        </Row>

        <Container fluid>
          {loading ? (
            <table className="table table-hover table-centered table-nowrap ">
              {SkeletonRow({ numRows: 4, numColumns: 1 })}
            </table>
          ) : (
            <Row className="align-items-center">

              <>
                {reviews &&
                  reviews.length > 0 &&
                  reviews.map((review, index) => (
                    <ReviewCard key={index} review={review} />
                  ))}
              </>
            </Row>
          )}
        </Container>

        <div className="d-flex justify-content-center">
          <ReactPaginate
            breakLabel="..."
            nextLabel="next >"
            onPageChange={event => handlePageChange(event.selected)}
            pageRangeDisplayed={6}
            pageCount={totalPages}
            previousLabel="< previous"
            renderOnZeroPageCount={null}
            containerClassName="pagination"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakClassName="page-item"
            breakLinkClassName="page-link"
            activeClassName="active"
          />
        </div>
      </div>
    </React.Fragment>
  )
}

export default Reviews