import { use } from "echarts";
import { post } from "helpers/api_helper";
import { GetLoggedInUser } from "helpers/backend_helper";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Row, Col, CardBody, Card, Container, Button, Label, FormGroup, Input } from "reactstrap";

const Login = () => {
const [password, setPassword] = useState("")
const [showPass1, setShowPass1] = useState(false)
const [showPass2, setShowPass2] = useState(false)
const [showPass3, setShowPass3] = useState(false)
const [user, setUser] = useState(GetLoggedInUser)
const [oldPass, setOldPass] = useState("")
const [passwordConfirm, setPasswordConfirm] = useState("")
const [id, setId] = useState(user?.id)

const changePassword = async (ev) => {
  ev.preventDefault()
  try {
    if (password !== passwordConfirm) {
      toast.error("Password do not match")
      return
    }
    const response = await post(`/managers/changepassword/${id}`, {
  password, oldPass,    
    })
    if (response.status === 200) {
      
      // redirect back to profile
      window.location.href = '/profile' 
      toast.success("Password changed successfully")

    }
  } catch (error) {
    toast.error("Failed to change password")
  }
}
  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2"></i>
        </Link>
      </div>
      <div className="account-pages my-5 pt-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={4}>
              <Card className="overflow-hidden">
                <div className="bg-primary">
                  <div className="text-primary text-center p-4">
                    <h5 className="text-white font-size-20">
                      Change your password
                    </h5>
                  </div>
                </div>

                <CardBody className="p-4">
                  <div className="p-3">
                    <form className="mt-4" action="#">

                    <FormGroup>
                          <Label className="form-label" htmlFor="userpassword">
                            OldPassword
                          </Label>
                          <div className="input-group">
                            <Input
                              name="password"
                              type={showPass1 ? "text" : "password"}
                              className="form-control"
                              placeholder="Enter old Password"
                              onChange={(ev)=>setOldPass(ev.target.value)}
                            />
                            <div className="input-group-append">
                              <Button
                                className="btn btn-outline-secondary"
                                type="button"
                                onClick={() => setShowPass1(!showPass1)}
                              >
                                {showPass1 ? (
                                  <i className="fa fa-eye-slash"></i>
                                ) : (
                                  <i className="fa fa-eye"></i>
                                )}
                              </Button>
                            </div>
                          </div>
                        </FormGroup>
                        <FormGroup>
                          <Label className="form-label" htmlFor="userpassword">
                            NewPassword
                          </Label>
                          <div className="input-group">
                            <Input
                              name="password"
                              type={showPass2 ? "text" : "password"}
                              className="form-control"
                              placeholder="Enter new Password"
                              onChange={(ev)=>setPassword(ev.target.value)}
                            />
                            <div className="input-group-append">
                              <Button
                                className="btn btn-outline-secondary"
                                type="button"
                                onClick={() => setShowPass2(!showPass2)}
                              >
                                {showPass2 ? (
                                  <i className="fa fa-eye-slash"></i>
                                ) : (
                                  <i className="fa fa-eye"></i>
                                )}
                              </Button>
                            </div>
                          </div>
                        </FormGroup>
                      
                        <FormGroup>
                          <Label className="form-label" htmlFor="userpassword">
                            Confirm Password
                          </Label>
                          <div className="input-group">
                            <Input
                              name="password"
                              type={showPass3 ? "text" : "password"}
                              className="form-control"
                              placeholder="confirm new Password"
                              onChange={(ev)=>setPasswordConfirm(ev.target.value)}
                            />
                            <div className="input-group-append">
                              <Button
                                className="btn btn-outline-secondary"
                                type="button"
                                onClick={() => setShowPass3(!showPass3)}
                              >
                                {showPass3 ? (
                                  <i className="fa fa-eye-slash"></i>
                                ) : (
                                  <i className="fa fa-eye"></i>
                                )}
                              </Button>
                            </div>
                          </div>
                        </FormGroup>

                      <div className="mb-3 row">
                        <div className="col-sm-6">
                        </div>
                        <div className="col-sm-6 text-end">
                          <button className="btn btn-primary w-md waves-effect waves-light" type="submit" onClick={(ev)=>(changePassword(ev))}>Change</button>
                        </div>
                      </div>

                    </form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Login;
