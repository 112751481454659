import React, { useState, useEffect } from "react"
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap"
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useParams,
  useLocation,
  Link,
  useNavigate,
} from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import user3 from "../../assets/images/users/user-3.jpg" // replace with your desired image
import defaultVehicle from "../../assets/images/driver_location.png"
import { toast } from "react-toastify"
import { post, del, get, put } from "../../helpers/api_helper"
import Dropzone from "react-dropzone"
import { LoadingButton, LoadingPage } from "pages/Utility/loading"
import { GetVehicleById, UpdateVehicle } from "helpers/backend_helper"


function VehicleSlug() {
  const [editingVehicle, setEditingVehicle] = useState(false)
  const [vehicle, setVehicle] = useState()
  const [isClicked, setIsClicked] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const navigate = useNavigate()
  const { id } = useParams()
  const location = useLocation()

  const handlePhotoAcceptedFiles = async acceptedFiles => {
    if (acceptedFiles.length > 0) {
      try {
        const file = acceptedFiles[0]
        var formData = new FormData()
        formData.append("files", file)

        var response = await post("/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        const fileUrl = response.data.fileUrls[file.name]
        setVehicle(prevData => ({
          ...prevData,
          photo: fileUrl,
        }))
      } catch (error) {
        toast.error("Error uploading file")
      }
    }
  }

  const handleEdit = () => {
    setEditingVehicle(true)
  }

  const handleSave = async () => {
    setIsClicked(true)
    try {
      await UpdateVehicle(id, vehicle, {}) 
      // put(`/vehicles/${id}`, vehicle)
      toast.success("success")
    } catch (error) {
      toast.error("error")
      setIsClicked(false)
    } finally {
      setIsClicked(false)
      setEditingVehicle(false)
    }
  }

  const handleDelete = async () => {
    setIsDeleting(true)
    setEditingVehicle(false)
    const res = await del(`/vehicles/${id}`, vehicle)
    if (res.status == 200) {
      toast.success("deleted")
      setTimeout(() => {
        navigate("/vehicles")
      }, 1000)
      setIsDeleting(false)
    } else {
      setIsDeleting(false)
      throw Error("error deleting")
    }
  }

  useEffect(() => {
    const fetchVehicleData = async () => {
      try {
        const response = await GetVehicleById(id, {})
        const data = await response
        console.log(data)
        setVehicle(data)
      } catch (error) {
        toast.error("Error fetching vehicle data")
        console.error("Error fetching vehicle data:", error)
      }
    }
    fetchVehicleData()
  }, [id])

  if (!vehicle) {
    return <LoadingPage />
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col md="7">
              <Breadcrumbs maintitle="hearts" title="Vehicle" />
            </Col>
            <Col md="5" className="align-self-end" />
          </Row>
          {/* Render Card */}
          <Card className="card">
            <CardBody className="card-body">
              <Row xl={4} md={4} className="d-flex justify-content-between m-4">
                <Dropzone onDrop={handlePhotoAcceptedFiles}>
                  {({ getRootProps, getInputProps }) => (
                    <div
                      {...getRootProps()}
                      style={{
                        backgroundImage: `url(${vehicle?.photo || defaultVehicle
                          })`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        height: "200px",
                        width: "200px",
                        borderRadius: "10%",
                        border: "2px solid #ccc",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <input {...getInputProps()} />
                      {editingVehicle && (
                        <div
                          style={{
                            position: "relative",
                            top: "20px",
                            left: "25px",
                            background: "rgba(255, 255, 255, 0.7)",
                            borderRadius: "50%",
                            padding: "0px",
                          }}
                        >
                          <i className="mdi mdi-cloud-upload display-6 text-muted"></i>
                        </div>
                      )}
                    </div>
                  )}
                </Dropzone>
                <br />
                <Row>
                  <div>
                    {isClicked || isDeleting ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        <LoadingButton />
                      </div>
                    ) : editingVehicle ? (
                      <>
                        <Button
                          color="primary"
                          className="btn-sm me-2"
                          onClick={handleSave}
                        >
                          Save
                        </Button>

                        <Button
                          color="danger"
                          className="btn-sm me-2"
                          onClick={handleDelete}
                        >
                          Delete
                        </Button>

                        <Button
                          color="warning"
                          className="btn-sm"
                          onClick={() => setEditingVehicle(false)}
                        >
                          Cancel
                        </Button>
                      </>
                    ) : (
                      <Button
                        color="primary"
                        className="btn-sm"
                        onClick={handleEdit}
                      >
                        Edit
                      </Button>
                    )}
                  </div>
                </Row>
              </Row>
              <hr />

              <Row>
                <div className="d-flex align-items-center">
                  <Form>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label for="car_name">Car Name</Label>
                          <Input
                            type="text"
                            name="car_name"
                            id="car_name"
                            value={vehicle.car_name}
                            onChange={e =>
                              setVehicle({
                                ...vehicle,
                                car_name: e.target.value,
                              })
                            }
                            disabled={!editingVehicle} // Disable based on editing state
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label for="plate_number">Plate Number</Label>
                          <Input
                            type="text"
                            name="plate_number"
                            id="plate_number"
                            value={vehicle.plate_number}
                            onChange={e =>
                              setVehicle({
                                ...vehicle,
                                plate_number: e.target.value,
                              })
                            }
                            disabled={!editingVehicle} // Disable based on editing state
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup className="d-flex align-items-center justify-content-evenly" >
                          <Label for="type">Type:</Label>
                          <Input
                            type="text"
                            name="type"
                            id="type"
                            value={vehicle.type}
                            disabled
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup
                          check
                          className="form-check form-switch mb-3"
                        >
                          <Label
                            check
                            className="form-check-label"
                            htmlFor="availability"
                          >
                            Is Active
                          </Label>
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            id="availability"
                            checked={
                              vehicle?.ambulance_type?.is_active || false
                            }
                            onClick={e => {
                              setVehicle({
                                ...vehicle,
                                ambulance_type: {
                                  ...vehicle.ambulance_type,
                                  is_active: e.target.checked,
                                },
                              })
                            }}
                            onChange={e => {
                              setVehicle({
                                ...vehicle,
                                ambulance_type: {
                                  ...vehicle.ambulance_type,
                                  is_active: e.target.checked,
                                },
                              })
                            }}
                            disabled={!editingVehicle} // Disable based on editing state
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </div>
                <Row>
                  <hr />
                </Row>
                <Row>
                  <Col>
                    {vehicle?.Driver ? (
                      <Link to={`/drivers/${vehicle?.Driver?.id}`}>
                        <div className="d-flex align-items-center mt-3">
                          <img
                            src={vehicle?.Driver?.photo || user3}
                            alt={vehicle?.Driver?.username}
                            className="avatar-sm rounded-circle me-2"
                            onError={e => {
                              e.target.src = user3
                            }}
                          />
                          <div>
                            <h6 className="mb-0">
                              {vehicle?.Driver?.name}
                            </h6>
                            <small className="text-muted">{vehicle?.Driver?.phone_number}</small>
                          </div>
                        </div>
                      </Link>
                    ) : (
                      <div>
                        <br />
                        <strong>no driver assigned</strong>
                      </div>
                    )}
                  </Col>
                  <Col>
                    {/*  */}
                    Is Active :{" "}
                    {vehicle?.ambulance_type?.is_active ? "True" : "False"}
                    <p>fixedPrice : {vehicle?.ambulance_type?.fixedPrice}</p>
                    <p>farePerKm : {vehicle?.ambulance_type?.farePerKm}</p>
                  </Col>
                </Row>

              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default VehicleSlug
