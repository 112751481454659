
import PropTypes from "prop-types"
import React from "react"
import {
  //   Container,
  //   Row,
  //   Col,
  //   Card,
  //   Alert,
  //   CardBody,
  Button,
  //   Form,
  //   FormFeedback,
  //   Label,
  //   Input
} from "reactstrap"

import avatar from "../../assets/images/users/user-4.jpg"
import { Link } from "react-router-dom"

function Inactive() {
  async function Logout() {
    // await get('/users/logout')
    await localStorage.removeItem("authUser")
    await localStorage.removeItem("token")
    await localStorage.removeItem("expiryDate")

    window.location = '/login'
  }
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <h1 style={{ color: "red" }}> You are currently inactive</h1>

      <Link
        to="/logout"
        style={{
          background: "red",
          padding: "10px 20px",
          borderRadius: "5px",
          color: "white",
          textDecoration: "none",
          marginTop: "10px",
        }}
        onClick={Logout}
        color="danger"
      >
        {" "}
        Logout
      </Link>
    </div>
  )
}

export default Inactive
