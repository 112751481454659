import { post, del, get, put } from "../../helpers/api_helper";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
} from "reactstrap";
import "react-toastify/dist/ReactToastify.css";

const SendMessageModal = ({ isModalOpen, toggleModal, modalType, booking }) => {
  const [message, setMessage] = useState("");
  const [driver, setDriver] = useState(booking.driver);
  const [loading, setLoading] = useState(false);

  const sendTheMessage = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!message) {
      toast.error("Message is required");
      setLoading(false);
      return;
    }

    // await new Promise((resolve) => setTimeout(resolve, 1000));
    try {

      // Logic to send the message can be added here
      const resp = await post("/messages/send-sms", {phone_number: driver.phone_number, message });
      toast.success("Message Sent");
    } catch (error) {
      console.error("Failed to send message", error);
      toast.error("Failed to send message");
    } finally {
      setLoading(false); // Set loading to false
      setMessage(""); // Clear the message after sending
    }
  };

  return (
    <React.Fragment>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Modal
        isOpen={isModalOpen}
        toggle={() => toggleModal(modalType)}
        size={modalType === "lg" ? "lg" : ""}
        scrollable={modalType === "scroll"}
      >
        <ModalHeader toggle={() => toggleModal(modalType)}>
          type the message to be sent
        </ModalHeader>
        <ModalBody>
          <form onSubmit={sendTheMessage}>
            <div className="mb-3">
              <label htmlFor="driverPhone" className="form-label">
                Driver:
              </label>
              <Input
                type="text"
                id="driverPhone"
                value={driver.phone_number}
                disabled
              />
            </div>

            <div className="mb-3">
              <label htmlFor="message" className="form-label">
                Message
              </label>
              <Input
                type="textarea"
                id="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                rows="4" // Adjust the number of visible rows
              />
            </div>

            <ModalFooter>
              <Button color="secondary" onClick={() => toggleModal(modalType)}>
                Close
              </Button>
              <Button color="primary" type="submit" disabled={loading}>
                {loading ? (
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                ) : (
                  "Send Message"
                )}
              </Button>
            </ModalFooter>
          </form>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default SendMessageModal;