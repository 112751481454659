// // // src/SocketContext.js
// // import React, { createContext, useContext, useEffect, useState } from 'react';
// // import { io } from 'socket.io-client';

// // const SocketContext = createContext();

// // export const useSocket = () => {
// //   return useContext(SocketContext);
// // };

// // export const SocketProvider = ({ children }) => {
// //   const [socket, setSocket] = useState(null);

// //   useEffect(() => {
// //     const newSocket = io('http://localhost:3001');
// //     setSocket(newSocket);

// //     return () => newSocket.close();
// //   }, []);

// //   return (
// //     <SocketContext.Provider value={socket}>
// //       {children}
// //     </SocketContext.Provider>
// //   );
// // };


// import React, { createContext, useContext, useEffect, useState } from 'react';
// import { io } from 'socket.io-client';

// const SocketContext = createContext();

// export const useSocket = () => {
//   return useContext(SocketContext);
// };

// export const SocketProvider = ({ children }) => {
//   const [socket, setSocket] = useState(null);

//   useEffect(() => {
//     const socketUrl = process.env.REACT_APP_SOCKET_URL || 'http://localhost:3001';
//     const newSocket = io(socketUrl, {
//       transports: ['websocket', 'polling'] // Ensures fallback options are available
//     });

//     setSocket(newSocket);
//     newSocket.on('connect', () => {
//       console.log('Connected to Socket.IO server');
//     });

//     newSocket.on('disconnect', () => {
//       console.log('Disconnected from Socket.IO server');
//     });


//     return () => {
//       newSocket.disconnect();
//     };
//   }, []);

//   if (!socket) {
//     return <div>Connecting to Socket.IO...</div>; // Placeholder or loading indicator
//   }

//   return (
//     <SocketContext.Provider value={socket}>
//       {children}
//     </SocketContext.Provider>
//   );
// };


import React, { createContext, useContext, useEffect, useState } from 'react';
import { io } from 'socket.io-client';

const SocketContext = createContext();


export const useSocket = () => {
  return useContext(SocketContext);
};

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const newSocket = io( process.env.REACT_APP_SOCKET_URL || 'http://localhost:3001'
    
  );
    setSocket(newSocket);

    newSocket.on('connect', (response) => {
      console.log(response)
      console.log('Connected to server');
    });

    newSocket.on('disconnect', () => {
      console.log('Disconnected from server');
    });

    // Add other event listeners as needed
    // newSocket.on('position_change', (position) => {
    //   console.log('Received position change:', position);
    // });

    return () => newSocket.close();
  }, []);

  return (
    <SocketContext.Provider value={socket}>
      {/* <div>test</div> */}
      {children}
    </SocketContext.Provider>
  );
};
