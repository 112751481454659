import React, { useState } from "react"
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap"
// import { toast } from "react-toastify"
import { post, del, get, put } from "../../helpers/api_helper"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { error } from "toastr"
import validatePhoneNumber from "utils/phone_number_validator"

const CreateProviderModal = ({ 
  isModalOpen, 
  toggleModal, 
  modalType,
  handleProviderNew
}) => {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone_number, setPhone_number] = useState("")
  const [is_government, setIs_government] = useState(false)
  const [is_active, setIs_active] = useState(true)
  // const [toast, setToast] = useState(false)
  // function showToast() {
  //   setToast(true);
  //   const timeoutId = setTimeout(() => {
  //     setToast(false);
  //   }, 6000);
  //   return () => clearTimeout(timeoutId);
  // }
  function allFilled() {
    return name !== "" && email !== "" && phone_number !== ""
  }

  async function createProvider(event) {
    if (allFilled() && validatePhoneNumber(phone_number)) {
      event.preventDefault()
      try {
        const response = await post("/providers/create", {
          name,
          email,
          phone_number,
          is_government,
          is_active,
          // photo,
        });
    
        if (response.status >= 200 && response.status < 300) {
          toast.success("Provider created successfully!");
          toggleModal(modalType); 
          handleProviderNew(response.data)// Assuming this function handles closing the modal
        } else {
          console.error("Error creating provider:", response.status);
          if (response.data?.error?.includes('name or email already exists')) {
            toast.error("Name or email already exists. Please choose another.");
          } else {
            toast.error("Error creating provider. Please try again.");
          }
        }
      } catch (error) {
        console.error("Unexpected error:", error);
        toast.error(error.response.data.error?? error);
      }
    } else {
      toast.error("Error fill all filleds or.phone number should be +251 format and 12 degits long.")
    }
  }
  // if (toast){
  //   return(<div>
  //     Toasted
  //   </div>)
  // }
  return (
    <React.Fragment>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Modal
        isOpen={isModalOpen}
        toggle={() => toggleModal(modalType)}
        size={modalType === "xlarge" ? "xl" : ""}
        scrollable={modalType === "scroll"}
      >
        <ModalHeader toggle={() => toggleModal(modalType)}>
          Provider Information
        </ModalHeader>
        <ModalBody>
          <form>
            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                Name
              </label>
              <input
                required
                type="text"
                className="form-control"
                id="name"
                value={name}
                onChange={ev => setName(ev.target.value)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Email
              </label>
              <input
                required
                type="email"
                className="form-control"
                id="email"
                value={email}
                onChange={ev => setEmail(ev.target.value)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="phone_number" className="form-label">
                Phone Number
              </label>
              <input
                required
                type="tel"
                className="form-control"
                id="phone_number"
                value={phone_number}
                onChange={ev => setPhone_number(ev.target.value)}
              />
            </div>
            <div className="mb-3 form-check">
              <input
                required
                type="checkbox"
                className="form-check-input"
                id="is_government"
                checked={is_government}
                onChange={ev => setIs_government(ev.target.checked)}
              />
              <label className="form-check-label" htmlFor="is_government">
                Is Government
              </label>
            </div>
            <div className="mb-3 form-check">
              <input
                required
                type="checkbox"
                className="form-check-input"
                id="is_active"
                checked={is_active}
                onChange={ev => setIs_active(ev.target.checked)}
              />
              <label className="form-check-label" htmlFor="is_active">
                Is Active
              </label>
            </div>
            <ModalFooter>
              <Button color="secondary" onClick={() => toggleModal(modalType)}>
                Close
              </Button>
              <Button color="primary" type="submit" onClick={createProvider}>
                Save Changes
              </Button>
            </ModalFooter>
          </form>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default CreateProviderModal
