import React, { useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from "reactstrap"

import {
  BrowserRouter as Router,
  Link,
  Route,
  Switch,
  useParams,
} from "react-router-dom"
import user2 from "../../assets/images/users/user-2.jpg"
import user3 from "../../assets/images/users/user-3.jpg"

const ComplaintsCard = ({ complaint }) => {
  return (
    <Card>
      <CardBody>
        <h4 className="card-title mb-4">Client complaint</h4>
        <div className=" d-flex justify-content-between">
          <div className="d-flex align-items-center mb-3">
            <img
              src={complaint?.client?.photo || user3}
              alt=""
              className="avatar-sm rounded-circle me-2"
              onError={e => {
                e.target.src = user3
              }}
            />
            <div>
              <h6 className="mb-0">{complaint?.client?.name}</h6>
              <h6 className="mb-0">{complaint?.client?.phone_number}</h6>
              <small className="text-muted">Client</small>
            </div>
          </div>
          <div>
            <p className="text-muted mb-3 pb-4">{complaint?.reason}</p>
            <p className="text-muted mb-3 pb-4">{complaint?.content}</p>
          </div>
        </div>
      </CardBody>
    </Card>
  )
}

export default ComplaintsCard
