function removeObjectProperties(editedDriverData) {
  return Object.keys(editedDriverData).reduce((acc, key) => {
    if (
      typeof editedDriverData[key] !== "object" ||
      editedDriverData[key] === null
    ) {
      acc[key] = editedDriverData[key]
    }
    return acc
  }, {})
}

export default removeObjectProperties
