import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  InputGroup,
  Input,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import user2 from "../../assets/images/users/user-2.jpg"
import user3 from "../../assets/images/users/user-3.jpg"

import SkeletonRow from "../../components/Common/skeletonRow"

import { Link, useLocation, useNavigate } from "react-router-dom"
//Import Breadcrumb
import ReactPaginate from "react-paginate"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { post, del, get, put } from "../../helpers/api_helper"
import { GetBookings, GetLoggedInUser } from "helpers/backend_helper"
import { LoadingPage } from "pages/Utility/loading"
function Bookings() {
  const navigate = useNavigate()
  const [bookings, setBookings] = useState([])
  const [loading, setLoading] = useState(true)
  const [filteredBookings, setFilteredBookings] = useState([])
  const [singlebtn, setSinglebtn] = useState(false)
  const [searchTerm, setSearchTerm] = useState("")
  const [selectedItem, setSelectedItem] = useState([
    <span className="badge bg-primary badge-xl">All</span>,
    0,
  ])
  const [bookingStatus, setBookingStatus] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [itemsPerPage, setItemsPerPage] = useState(12)
  const [role, setRole] = useState("")
  const [all, setAll] = useState(false)
  useEffect(() => {
    const fetchRequests = async () => {
      try {
        setLoading(true)
        const authUser = GetLoggedInUser()
        setRole(authUser.role_id)
        if (role !== "1") {
          const response = await GetBookings({
            params: {
              page: currentPage,
              limit: itemsPerPage,
              all: all,
              providerId: authUser.provider_id,
            },
          })
          const { data, total_pages } = response
          setTotalPages(total_pages)
          setBookings(data)
          setFilteredBookings(data)
        } else {
          const response = await GetBookings({
            params: { page: currentPage, limit: itemsPerPage, all: all },
          })
          const { data, total_pages } = response
          setTotalPages(total_pages)
          setBookings(data)
          setFilteredBookings(data)
        }
        // setTotalPages(total_pages);
      } catch (error) {
        console.error("Error fetching requests:", error)
      } finally {
        setLoading(false)
      }
    }

    fetchRequests()
  }, [currentPage, itemsPerPage])

  const handlePageChange = pageNumber => {
    setCurrentPage(pageNumber)
  }

  const filterBookings = () => {
    return bookings.filter(booking => {
      const searchRegex = new RegExp(searchTerm, "i")
      const isSearchTermMatch =
        searchTerm === "" ||
        searchRegex.test(booking.client?.username) ||
        searchRegex.test(booking.client?.email) ||
        searchRegex.test(booking.client?.phone_number) ||
        searchRegex.test(booking.driver?.name) ||
        searchRegex.test(booking.driver?.email) ||
        searchRegex.test(booking.driver?.phone_number) ||
        searchRegex.test(booking.provider?.name) ||
        searchRegex.test(booking.provider?.email) ||
        searchRegex.test(booking.provider?.phone_number)

      switch (bookingStatus) {
        case 0:
          return isSearchTermMatch
        case 1:
          return booking.status === "PENDING" && isSearchTermMatch
        case 2:
          return booking.status === "ACCEPTED" && isSearchTermMatch
        case 3:
          return booking.status === "ARRIVED" && isSearchTermMatch
        case 4:
          return booking.status === "STARTED" && isSearchTermMatch
        case 5:
          return booking.status === "COMPLETED" && isSearchTermMatch
        case 6:
          return booking.status === "CANCELLED" && isSearchTermMatch
        case 7:
          return booking.status === "EXPIRED" && isSearchTermMatch
        case 8:
          return booking.status === "REJECTED" && isSearchTermMatch
        default:
          return false
      }
    })
  }
  const dropdownMenuStyle = {
    maxHeight: "150px", // Adjust this value as needed
    overflowY: "auto",
  }
  useEffect(() => {
    setFilteredBookings(filterBookings())
  }, [bookings, bookingStatus, searchTerm])

  const handelCreate = type => {
    navigate("create")
  }
  if (!bookings) {
    return <LoadingPage />
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row className="align-items-center row justify-content-end">
            <Col md="4">
              <Breadcrumbs maintitle="hearts" title="Bookings" />
            </Col>
            <Col md="8" className="d-flex justify-content-end">
              <div className="my-4 text-center">
                {role !== "1" ? (
                  <Button
                    color="primary"
                    onClick={handelCreate}
                    className="m-0"
                  >
                    Create Booking
                  </Button>
                ) : (
                  ""
                )}
              </div>
            </Col>
          </Row>

          <Row className="z-2 relative d-flex align-items-center justify-content-end">
            <Col md="4">
              <Dropdown
                isOpen={singlebtn}
                toggle={() => setSinglebtn(!singlebtn)}
              >
                <DropdownToggle className="btn btn-secondary" caret>
                  {selectedItem[0] || "Status"}{" "}
                  <i className="mdi mdi-chevron-down" />
                </DropdownToggle>
                <DropdownMenu style={dropdownMenuStyle}>
                  <DropdownItem
                    onClick={() => {
                      setSelectedItem([
                        <span
                          className="badge bg-primary"
                          style={{ fontSize: "1.25rem" }}
                        >
                          All
                        </span>,
                        0,
                      ])
                      setBookingStatus(0)
                    }}
                  >
                    <span
                      className="badge bg-primary"
                      style={{ fontSize: "1rem" }}
                    >
                      All
                    </span>
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      setSelectedItem([
                        <span
                          className="badge bg-warning"
                          style={{ fontSize: "1rem" }}
                        >
                          Pending
                        </span>,
                        1,
                      ])
                      setBookingStatus(1)
                    }}
                  >
                    <span
                      className="badge bg-warning"
                      style={{ fontSize: "1rem" }}
                    >
                      Pending
                    </span>
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      setSelectedItem([
                        <span
                          className="badge bg-success"
                          style={{ fontSize: "1rem" }}
                        >
                          Accepted
                        </span>,
                        2,
                      ])
                      setBookingStatus(2)
                    }}
                  >
                    <span
                      className="badge bg-success"
                      style={{ fontSize: "1rem" }}
                    >
                      Accepted
                    </span>
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      setSelectedItem([
                        <span
                          className="badge bg-primary"
                          style={{ fontSize: "1rem" }}
                        >
                          Arrived
                        </span>,
                        3,
                      ])
                      setBookingStatus(3)
                    }}
                  >
                    <span
                      className="badge bg-primary"
                      style={{ fontSize: "1rem" }}
                    >
                      Arrived
                    </span>
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      setSelectedItem([
                        <span
                          className="badge bg-primary"
                          style={{ fontSize: "1rem" }}
                        >
                          Started
                        </span>,
                        4,
                      ])
                      setBookingStatus(4)
                    }}
                  >
                    <span
                      className="badge bg-primary"
                      style={{ fontSize: "1rem" }}
                    >
                      Started
                    </span>
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      setSelectedItem([
                        <span
                          className="badge bg-success"
                          style={{ fontSize: "1rem" }}
                        >
                          Completed
                        </span>,
                        5,
                      ])
                      setBookingStatus(5)
                    }}
                  >
                    <span
                      className="badge bg-success"
                      style={{ fontSize: "1rem" }}
                    >
                      Completed
                    </span>
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      setSelectedItem([
                        <span
                          className="badge bg-danger"
                          style={{ fontSize: "1rem" }}
                        >
                          Cancelled
                        </span>,
                        6,
                      ])
                      setBookingStatus(6)
                    }}
                  >
                    <span
                      className="badge bg-danger"
                      style={{ fontSize: "1rem" }}
                    >
                      Cancelled
                    </span>
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      setSelectedItem([
                        <span
                          className="badge bg-danger"
                          style={{ fontSize: "1rem" }}
                        >
                          Expired
                        </span>,
                        7,
                      ])
                      setBookingStatus(7)
                    }}
                  >
                    <span
                      className="badge bg-danger"
                      style={{ fontSize: "1rem" }}
                    >
                      Expired
                    </span>
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      setSelectedItem([
                        <span
                          className="badge bg-danger"
                          style={{ fontSize: "1rem" }}
                        >
                          Rejected
                        </span>,
                        8,
                      ])
                      setBookingStatus(8)
                    }}
                  >
                    <span
                      className="badge bg-danger"
                      style={{ fontSize: "1rem" }}
                    >
                      Rejected
                    </span>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </Col>

            <Col md="4" className="mb-3 mb-md-0">
              <form className="app-search d-block">
                <div className="position-relative">
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                  />
                  <span className="fa fa-search"></span>
                </div>
              </form>
            </Col>
          </Row>
          <div className="table-responsive">
            <table className="table table-hover">
              <thead>
                <th ><input type="checkbox" /></th>
                <th scope="col">Date</th>
                <th scope="col">Distance</th>
                <th scope="col" style={{ maxWidth: "200px" }}>
                  Pickup Address
                </th>
                <th scope="col" style={{ width: "250px" }}>
                  Dropoff Address
                </th>
                <th scope="col">Status</th>
                <th scope="col">Client </th>
                <th scope="col"> Actions</th>
              </thead>
              <tbody>
                {loading ? (
                  <> {SkeletonRow({ numRows: 4, numColumns: 9 })} </>
                ) : (
                  <>
                    {filteredBookings &&
                      filteredBookings.length &&
                      filteredBookings.map((request, index) => (
                        <RequestRow
                          key={index}
                          idx={{ index: index }}
                          request={request}
                        />
                      ))}
                  </>
                )}
              </tbody>
            </table>

            {/* <div>
        {Array.from({ length: totalPages }, (_, i) => i + 1).map((pageNumber) => (
          <Button
            key={pageNumber}
            onClick={() => handlePageChange(pageNumber)}
            disabled={currentPage === pageNumber}
          >
            {pageNumber}
          </Button>
        ))}
      </div> */}
            <div className="d-flex justify-content-center">
              <ReactPaginate
                breakLabel="..."
                nextLabel="next >"
                onPageChange={event => handlePageChange(event.selected)}
                pageRangeDisplayed={6}
                pageCount={totalPages}
                previousLabel="< previous"
                renderOnZeroPageCount={null}
                containerClassName="pagination"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakClassName="page-item"
                breakLinkClassName="page-link"
                activeClassName="active"
              />
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Bookings

const RequestRow = ({
  idx: { index },
  request: {
    id,
    price,
    distance,
    status,
    client,
    pickup_location,
    dropoff_location,
    provider,
    createdAt,
    // : { name: bookingName },
  },
}) => {
  const dateObject = new Date(createdAt)

  // Format the date using your desired format
  const formattedDate = dateObject.toLocaleString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    // timeZoneName: "short",
  })
  return (
    <tr>
      {/* <td>{index}</td> */}
      <td><input type="checkbox" /></td>

      <td>{formattedDate}</td>

      <td>{distance / 1000}km</td>
      <td>{pickup_location?.address ? pickup_location.address : "N/A"}</td>
      <td>{dropoff_location?.address ? dropoff_location.address : "N/A"}</td>

      <td>
        <span className={`badge bg-${getStatusColor(status)}`}>{status}</span>
        <div>{provider?.name ? provider.name : "Loading..."}</div>
      </td>
      <td>
        <div style={{ display: "flex" }}>
          <img src={user2} alt="" className="avatar-xs rounded-circle me-2" />{" "}
          <div>
            <div>{client?.phone_number}</div>
            <div>{client?.name}</div>
          </div>
        </div>
      </td>
      <td>
        <div>
          <Link
            to={`${location.pathname}/${id}`}
            className="btn btn-primary btn-sm"
          >
            Edit
          </Link>
        </div>
      </td>
    </tr>
  )
}

const getStatusColor = status => {
  switch (status) {
    case "PENDING":
      return "warning"
    case "ACCEPTED":
    case "ARRIVED":
    case "STARTED":
    case "COMPLETED":
      return "success"
    case "CANCELLED":
    case "EXPIRED":
    case "REJECTED":
      return "danger"
    default:
      return "secondary"
  }
}
