// MapProvider.js
import React from "react"
import { useJsApiLoader } from "@react-google-maps/api"
import { LoadingPage } from "pages/Utility/loading"
const MapProvider = ({ children }) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ["places", "geometry"],
  })

  if (!isLoaded) {
    return(
    <LoadingPage />)
  }

  return <>{children}</>
}

export default MapProvider
