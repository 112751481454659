import React from 'react'

function SkeletonRow({ numRows, numColumns }) {
    const renderSkeletonRow = () => {
        const rows = [];
        for (let i = 0; i < numRows; i++) {
            const columns = [];
            for (let j = 0; j < numColumns; j++) {
                columns.push(
                    <td key={j}>
                        <div className="skeleton" style={{ Width: "80%" }}></div>
                    </td>
                );
            }
            rows.push(<tr key={i}>{columns}</tr>);
        }
        return rows;
    };

    return <>{renderSkeletonRow()}</>;
}

export default SkeletonRow;