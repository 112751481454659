import React, { useEffect, useState } from "react"
import {
  Card,
  CardBody,
  CardImg,
  CardSubtitle,
  CardText,
  CardTitle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Container,
  Button,
  ButtonGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

import user3 from "../../assets/images/users/user-3.jpg"
import car3 from "../../assets/images/driver_location.png"
const DriverCard = ({ driver, onclick }) => {
  return (
    <div className="d-flex justify-content-between w-100"
      onclick={onclick}
    >
      <div>
        <img
          src={driver?.photo || user3}
          alt={driver?.username}
          className="avatar-sm rounded-circle me-2"
          onError={e => {
            e.target.src = user3
          }}
        />
        <div>
          <h6 className="mb-0">{driver?.name}</h6>

          <h6 className="mb-0">{driver?.username}</h6>
          <small className="text-muted">{driver?.phone_number}</small>
        </div>
      </div>

      <div className="p-3 d-flex justify-content-center">
        <img
          style={{ width: "50px", height: "50px", borderRadius: "30%" }}
          src={driver?.vehicle?.photo || car3}
          alt={driver?.vehicle?.name}
          className=""
          onError={e => {
            e.target.src = car3
          }}
        />
        <div>
          <h6 className="mb-0">{driver?.vehicle?.plate_number}</h6>

          <h6 className="mb-0">{driver?.vehicle?.car_name}</h6>
          <small className="text-muted">
            {driver?.vehicle?.ambulance_type?.name}
          </small>
        </div>
      </div>
    </div>
  )
}

export default DriverCard
