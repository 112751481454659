import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap"
import { toast } from "react-toastify"
import { post, del, get, put } from "../../helpers/api_helper"
import SendMessageModal from "./SendMessageModal"
import { useParams } from "react-router-dom"
import MiniMap from "./MiniMap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import user3 from "../../assets/images/users/user-3.jpg"
import {
  ChangeBookingDriver,
  GetDrivers,
  GetLoggedInUser,
  UpdateBooking,
} from "helpers/backend_helper"
import { LoadingButton, LoadingPage } from "pages/Utility/loading"
import DriverCard from "./DriverCard"
import { Vehicles } from "helpers/url_helper"
const BookingsSlug = () => {
  const { id } = useParams()
  const [booking, setBooking] = useState(null)
  const [drivers, setdrivers] = useState(null)
  const [editMode, setEditMode] = useState(false)
  const [editDriverMode, setEditDriverMode] = useState(false)
  const [isClicked, setIsClicked] = useState(false)
  const [isOpen, setIsOpen] = useState(true)


  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalType, setModalType] = useState("")

  const toggleModal = type => {
    setModalType(type)
    setIsModalOpen(!isModalOpen)
  }

  const handleStatusChange = async event => {
    setBooking(prevBooking => ({
      ...prevBooking,
      status: event.target.value,
    }))
  }

  const handleDriverChange = async event => {
    setBooking(prevBooking => ({
      ...prevBooking,
      driver_id: event.id,
      driver: event,
    }))
  }
  const handleSaveStatusChanges = async () => {
    // Save the updated booking data
    console.log("Saving changes:", booking)
    setIsClicked(true)
    try {
      await UpdateBooking(id, booking)
      toast.success("Status updated")
      setIsClicked(false)
    } catch (error) {
      setIsClicked(false)
      toast.error("could not update Status ")
    } finally {
      setEditMode(false)
      setIsClicked(false)
    }
  }

  // const handleSaveDriverChanges = async () => {
  //   // Save the updated booking data
  //   console.log("Saving changes:", booking)
  //   setEditMode(false)
  //   try {
  //     await UpdateBooking(id, booking)
  //     toast.success("Driver updated")
  //   } catch (error) {
  //     toast.error("could not update Driver ")
  //   }
  // }

  const changeDriver = async () => {
    setEditMode(false)
    try {
      setIsClicked(true)
      console.log(booking?.driver_id)
      await ChangeBookingDriver(id, { driver_id: booking?.driver_id })
      toast.success("New Driver Assigned")
      setIsClicked(false)
    } catch (error) {
      setIsClicked(false)
      setEditMode(false)
      console.log(error)
      toast.error("could not assign driver ")
    }
  }

  useEffect(() => {
    var authUser = GetLoggedInUser()
    GetDrivers({
      params: { providerId: authUser.provider_id, is_active: true, all: true },
    })
      .then(({ data }) => {
        // Filter drivers based on your criteria
        const filteredDrivers = data.filter(driver => driver.vehicle);
        setdrivers(filteredDrivers);
      })
      .catch(error => console.error(error))
  }, [])
  useEffect(() => {
    // Fetch the manager data from the server or other data source
    const fetchBookingData = async () => {
      if (id != null)
        try {
          const response = await get(`/bookings/${id}`)
          const data = await response
          setBooking(data)
        } catch (error) {
          console.error("Error fetching booking data:", error)
          toast.error("Error fetching booking data")
        }
    }
    fetchBookingData()
  }, [id])

  const handelSendMessage = type => {
    navigate("create")
  }

  if (!booking) {
    return <LoadingPage />
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row className="align-items-center row justify-content-end">
            <Col md="4">
              <Breadcrumbs maintitle="hearts" title="Bookings" />
            </Col>
            <Col md="8" className="d-flex justify-content-end">
              <div className="my-4 text-center">
                <Button
                  color="primary"
                  onClick={toggleModal}
                  className="m-0"
                >
                  send message
                </Button>
                <SendMessageModal
                  isModalOpen={isModalOpen}
                  toggleModal={toggleModal}
                  modalType={modalType}
                  booking={booking}

                />
              </div>
            </Col>
          </Row>

          <h1>Booking Details</h1>
          <Row>
            <MiniMap booking={booking} google={window.google} />
          </Row>

          <Row>
            <Col>
              <Card>
                <CardBody>
                  <h4>Booking Details</h4>
                  {/* Client details */}
                  <p>Distance: {booking?.distance}</p>
                  <p>Duration: {booking?.duration}</p>
                  <p>Estimated Price: {booking?.price}</p>
                  <p>createdAt: {booking?.createdAt}</p>
                </CardBody>
              </Card>
            </Col>

            <Col>
              <Card>
                <CardBody>
                  <h4>Booking Details</h4>
                  <Form>
                    <FormGroup>
                      <Label for="status">Status</Label>
                      <Input
                        type="select"
                        id="status"
                        name="status"
                        value={booking.status}
                        onChange={handleStatusChange}
                        disabled={!editMode}
                        style={{
                          fontSize: "1.25rem",
                          padding: "0.5rem",
                          color: "black",
                          fontWeight: "bold",
                        }}
                      >
                        <option
                          value="PENDING"
                          style={{ backgroundColor: "yellow" }}
                        >
                          PENDING
                        </option>
                        <option
                          value="ACCEPTED"
                          style={{ backgroundColor: "blue" }}
                        >
                          ACCEPTED
                        </option>
                        <option
                          value="ARRIVED"
                          style={{ backgroundColor: "blue" }}
                        >
                          ARRIVED
                        </option>
                        <option
                          value="STARTED"
                          style={{ backgroundColor: "blue" }}
                        >
                          STARTED
                        </option>
                        <option
                          value="COMPLETED"
                          style={{ backgroundColor: "green" }}
                        >
                          COMPLETED
                        </option>
                        <option
                          value="CANCELLED"
                          style={{ backgroundColor: "red" }}
                        >
                          CANCELLED
                        </option>
                        <option
                          value="EXPIRED"
                          style={{ backgroundColor: "orange" }}
                        >
                          EXPIRED
                        </option>
                        <option
                          value="REJECTED"
                          style={{ backgroundColor: "tomato" }}
                        >
                          REJECTED
                        </option>
                      </Input>
                    </FormGroup>

                    {/* Add other booking details here */}
                    {isClicked ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "right",
                          width: "100%",
                        }}
                      >
                        <LoadingButton />
                      </div>
                    ) : editMode ? (
                      <>
                        <Button
                          color="primary"
                          onClick={handleSaveStatusChanges}
                        >
                          Save Changes
                        </Button>
                        {"  "}
                        <Button
                          color="danger"
                          onClick={() => setEditMode(false)}
                        >
                          Cancel
                        </Button>
                      </>
                    ) : (
                      <Button color="primary" onClick={() => setEditMode(true)}>
                        Edit
                      </Button>
                    )}
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col>
              <Card>
                <CardBody>
                  <h4>Client Details</h4>
                  {/* Client details */}
                  <p>Phone Number: {booking?.client?.phone_number}</p>
                  <p>Name: {booking?.client?.name}</p>
                  <p>Email: {booking?.client?.email}</p>
                  {/* <p>Photo: {booking.client.photo}</p> */}
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card>
                <CardBody>
                  <h4>Driver Details</h4>
                  {/* Driver details */}
                  <p>Phone Number: {booking.driver?.phone_number}</p>
                  <p>Name: {booking.driver?.name}</p>
                  <p>Email: {booking.driver?.email}</p>
                  <>
                    {editDriverMode ? (
                      <div className="mt-2">
                        <div className="mb-3">
                          <label htmlFor="booking" className="form-label">
                            Available Drivers
                          </label>
                          <div
                            onClick={e => {
                              e.preventDefault()
                              setIsOpen(!isOpen)
                            }}
                          >
                            {isOpen ? (
                              <ul className="list-group">
                                {Array.isArray(drivers) &&
                                  drivers.length > 0 &&
                                  drivers.map(driver => (
                                    <li
                                      key={driver.id}
                                      className={`list-group-item ${driver.id === booking.driver_id
                                        ? "active"
                                        : ""
                                        } mb-3`}
                                      onClick={() => {
                                        handleDriverChange(driver)
                                        setIsOpen(false)
                                      }}
                                    >

                                      <div d-flex className="d-flex justify-content-between">

                                        <div className="d-flex align-items-center ">
                                          <img
                                            src={driver?.photo || user3}
                                            alt={driver?.username}
                                            className="avatar-sm rounded-circle me-2"
                                            onError={e => {
                                              e.target.src = user3
                                            }}
                                          />


                                          <div>

                                            <h6 className="mb-0">
                                              {driver?.name}
                                            </h6>
                                            {/* <h6 className="mb-0">
                                            {driver?.username}
                                            </h6> */}
                                            <small className="text-muted">
                                              {driver?.phone_number}
                                            </small>
                                          </div>

                                        </div>


                                        <div>
                                          <h6 className="mb-0 ">
                                            {driver?.vehicle?.car_name}
                                          </h6>
                                          <small className="text-muted" >
                                            {driver?.vehicle?.plate_number}
                                          </small>
                                          <h6>
                                            {driver?.vehicle?.ambulance_type.name}
                                          </h6>
                                        </div>
                                      </div>
                                    </li>
                                  ))}
                              </ul>
                            ) : (
                              <div className="d-flex align-items-center">
                                <span className="btn btn-primary">
                                  Select a driver
                                </span>
                                <i className="bi bi-chevron-down ms-auto"></i>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="d-flex justify-content-end">
                          {!isClicked ? (
                            <button
                              className="btn btn-primary"
                              onClick={changeDriver}
                            >
                              Save Changes
                            </button>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "right",
                                width: "100%",
                              }}
                            >
                              <LoadingButton />
                            </div>
                          )}

                          <button
                            className="btn btn-danger ms-2"
                            onClick={() => {
                              setIsOpen(false)
                              setEditDriverMode(false)
                            }}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    ) : (
                      <Button
                        color="primary"
                        onClick={() => setEditDriverMode(true)}
                      >
                        Edit
                      </Button>
                    )}
                  </>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col>
              <Card>
                <CardBody>
                  <h4>Pickup Location</h4>
                  {/* Pickup location details */}
                  <p>Address: {booking?.pickup_location?.address}</p>
                  <Row>
                    <Col>
                      <p>Latitude: {booking?.pickup_location?.latitude}</p>
                    </Col>
                    <Col>
                      <p>Longitude: {booking?.pickup_location?.longitude}</p>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card>
                <CardBody>
                  <h4>Dropoff Location</h4>
                  {/* Dropoff location details */}
                  <p>Address: {booking?.dropoff_location?.address}</p>
                  <Row>
                    <Col>
                      <p>Latitude: {booking?.dropoff_location?.latitude}</p>
                    </Col>
                    <Col>
                      <p>Longitude: {booking?.dropoff_location?.longitude}</p>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col>
              <Card>
                <CardBody>
                  <h4>Review Details</h4>
                  {/* Review details */}
                  {booking?.review != null ?? (
                    <>
                      <p>Rating: {booking?.review?.rating}</p>
                      <p>Comment: {booking?.review?.comment}</p>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card>
                <CardBody>
                  <h4>Cancellation Details</h4>
                  <p>Reason: {booking?.cancellation_reasons?.reason}</p>


                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card>
                <CardBody>
                  <h4>Payment Details</h4>
                  {/* Review details */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default BookingsSlug
