import React, { useEffect, useState } from "react"
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Dropdown,
  FormGroup,
} from "reactstrap"
// import { toast } from "react-toastify"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { GetProviders } from "helpers/backend_helper"
import { post } from "helpers/api_helper"
import { LoadingButton } from "pages/Utility/loading"

const CreateNotificationModal = ({ isModalOpen, toggleModal, modalType , handleNotificationNew, images, setImages, body, setBody, title, topic, setTopic, setTitle,url, setUrl }) => {
    
    const [clicked, setClicked] = useState(false)

    const handleAcceptedFiles = async (event) => {
        const acceptedFiles = event.target.files;
        console.log("Accepted files:", acceptedFiles); // Debugging statement
  
        if (acceptedFiles.length > 0) {
            try {
                const file = acceptedFiles[0];
                var formData = new FormData();
                formData.append("files", file);
  
                // Log the contents of the FormData
                for (const [key, value] of formData.entries()) {
                    console.log("key:value", `${key}:`, value)
                }
  
                var response = await post("/upload", ...formData, {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  })
                  const fileUrl = response.data.fileUrls[file.name];
                setImages((prevImages) => [...prevImages, fileUrl]);
                console.log("Uploaded file URL:", response.data); // Debugging statement
            } catch (error) {
                toast.error("Error uploading file");
                console.error("Error uploading file:", error);
            }
        } else {
            console.log("No files were accepted."); // Debugging statement
        }
    };
    function handelAddUrl(event){
        setUrl(event.target.value)
    }
    function handleDelete(){
        setImages([]);
            }
    const handleUpload = async () => { 
        try {
            setClicked(true)
            // if (url !== ''){
            //     console.log(url)
            //     setImages([url])
            //     console.log(images)
            // }
            const response = await post('/push_notifications', { body, title, image: url ||images[0], topic });
            if (response) {
                setBody('');
                setUrl('');
                setTitle('');
                setTopic('');
                setImages([]);
                toast.success('Upload successful!');
                // handleNotificationNew(response.data)
                // setBanners(...Banners, response);
                
                setClicked(false)
            } else {
                setClicked(false)
                throw new Error('Upload failed');
            }
        } catch (error) {
            setClicked(false)
            toast.error('Upload failed!');
            console.error('Error uploading :', error);
        }
    };

  return (
    <React.Fragment>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Modal
        isOpen={isModalOpen}
        toggle={() => toggleModal(modalType)}
        size={modalType === "xlarge" ? "xl" : ""}
        scrollable={modalType === "scroll"}
      >
        <ModalHeader toggle={() => toggleModal(modalType)}>
          Banner Information
        </ModalHeader>
        <ModalBody>
        <div style={{ marginBottom: '20px' }} >
        <FormGroup className="mb-3">
              <input
                required
                type="text"
                className="form-control"
                placeholder="Topic"
                value={topic}
                onChange={(e) => setTopic(e.target.value)}
              />
            </FormGroup>
          <FormGroup className="mb-3">
              <input
                required
                type="text"
                className="form-control"
                placeholder="Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </FormGroup>
                <input
                required
                    type="text"
                    style={{height:"70px"}}
                    className="form-control"
                   placeholder="body "
                    value={body}
                    onChange={(e) => setBody(e.target.value)}
        
                />
                          <FormGroup className="mb-3">
            </FormGroup>

            </div>
            <div className="mb-3" style={{ display: 'flex' , justifyContent: 'space-left items-cente', alignItems:"center"}}>
            <input
                required
                    type="text"
                    style={{height:"30px"}}
                    className="form-control"
                   placeholder="image Url or "
                    value={url}
                    onChange={(event) => setUrl(event.target.value)}
        
                />
            <label
                style={{
                    cursor: 'pointer',
                    width: '30px',
                    height: '30px',
                    marginTop: '0.75rem',
                    borderRadius: '1rem',
                    color: 'black',
                    fontWeight: '600',
                    marginRight: "20px"
                }}
            >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5" />
                </svg>

                <input
                    required
                    type="file"
                    style={{ display: 'none' }} // Hidden input
                    multiple
                    placeholder="minimum 3"
                    onChange={handleAcceptedFiles}
                />
            </label>
            </div>

            {images?.map((image, index) => (
    <div key={index} style={{ position: 'relative', margin: '10px' }}>
        {image instanceof File ? (
            <img
                src={URL.createObjectURL(image)} // Create object URL for File
                alt={`preview-${index}`}
                style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '5px' }}
            />
        ) : (
            <img
                src={image} // Use the URL directly if it's already a URL
                alt={`uploaded-${index}`}
                style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '5px' }}
            />
        )}
        <button
            onClick={() => handleDelete(index)}
            style={{
                position: 'absolute',
                top: '5px',
                right: '5px',
                backgroundColor: 'red',
                color: 'white',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
            }}
        >
            X
        </button>
    </div>
))}
            {!clicked? (<Button color="primary" className="m-0" onClick={handleUpload} style={{ marginBottom: '20px', padding: '10px 15px' }}>
        Send
    </Button>): ( <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                    <LoadingButton />
                  </div> )
                  }
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default CreateNotificationModal
