import React, { useState } from 'react'
import { Button, Table } from 'reactstrap'
import CreateNotificationModal from './createNotification'
import user2 from "../../assets/images/users/user-2.jpg"
import { sample } from 'lodash'

export default function Notification() {
  
  const [images, setImages] = useState([]);
  const [body, setBody] = useState('');
  const [title, setTitle] = useState('');
  const [topic, setTopic ] = useState('');
  const [url, setUrl] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [notifications, setNotifications] = useState([]);
  
  const toggleModal = type => {
    setModalType(type);
    setIsModalOpen(!isModalOpen);
  }

  const handleNotificationNew = noti => {
    setNotifications([...notifications, noti]);
  }

  const handeleClick = (id) => {
    const sample = sampleNotifications.find(noti => noti.id === id);
    if (sample) {
      setTitle(sample.title);
      setTopic(sample.topic);
      setImages([])
      setImages((prevImages) => [...prevImages, sample.image]);
      setBody(sample.body);
      setUrl(sample.image);
      toggleModal("scroll");
    }
  }

  const sampleNotifications = [
    {
      id: 1,
      title: "Update on booking",
      topic: "Update",
      body: "You have a new booking, would you like to check our new way of booking?",
      image: user2,
    },
    {
      id: 2,
      title: "New booking",
      topic: "Booking",
      body: "You have a new booking, would you like to check our new way of booking?",
      image: user2,
    },
    {
      id: 3,
      title: "Old booking",
      topic: "Old Booking",
      body: "You have a new booking, would you like to check our new way of booking?",
      image: user2,
    },
  ];

  return (
    <div>
      <div style={{display: "flex", position:"relative", paddingTop:"80px", justifyContent:"space-between"}}>
        <h1>Notifications</h1>
        <div className='mb-3' style={{display:"flex", justifyContent:"right", padding:"20px"}}>
          <Button color="primary" className="m-0" onClick={() => toggleModal("scroll")} style={{ padding: '10px 15px' }}>
            Create Notification
          </Button>
        </div>
      </div>

      <CreateNotificationModal 
        isModalOpen={isModalOpen}
        toggleModal={toggleModal}
        modalType={modalType}
        handleNotificationNew={handleNotificationNew}
        images={images}
        setImages={setImages}
        body={body}
        setBody={setBody}
        title={title}
        setTitle={setTitle}
        topic={topic}
        setTopic={setTopic}
        url={url}
        setUrl={setUrl}
      />

      <Table>
        <tbody>
          {sampleNotifications.map(noti => (
            <NotificationRow
              key={noti.id}
              id={noti.id}
              title={noti.title}
              body={noti.body}
              topic={noti.topic}
              image={noti.image}
              handeleClick={() => handeleClick(noti.id)} // Pass the function reference correctly
            />
          ))}
        </tbody>
      </Table>
    </div>
  );
}

const NotificationRow = ({
  title,
  topic,
  body,
  image,
  handeleClick
}) => {
  return (
    <tr className="hover-effect" 
      onClick={handeleClick} // Pass the function reference directly
      style={{ cursor: "pointer" }}>
      <td style={{ borderBottom: "2px solid gray" }}>
        <div className="d-flex mb-3" style={{ alignItems: "center" }}>
          <div className="h-full">
            <img
              src={image || user2}
              alt=""
              className="avatar-xs me-2"
              style={{ width: "60px", height: "60px", backgroundColor: "gray" }}
              onError={e => (e.target.src = user2)}
            />
          </div>
          <div>
            <div className='mb-3'>Title: {title}</div>
            <div>
              <span className="me-2" style={{ color: "gray" }}>Topic: {topic}</span>
            </div>
          </div>
        </div>
      </td>
      <td style={{ color: "black", padding: "10px", borderBottom: "2px solid gray" }}>
        <p>{body}</p>
      </td>
    </tr>
  );
}
