import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
} from "./actionTypes";


// export const loginUser = (user, navigate) => async (dispatch) => {
//   try {
//     dispatch({ type: LOGIN_USER });

//     const response = await fetch('https://ride-haling-backend.onrender.com/api/auth/login', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify(user),
//     });

//     if (!response.ok) {
//       const error = await response.json();
//       throw new Error(error.message);
//     }

//     const { token, user } = await response.json();
//     localStorage.setItem('token', token);
//     dispatch(loginSuccess(user));
//     navigate('/dashboard');
//   } catch (error) {
//     dispatch(apiError(error.message));
//   }
// };

export const loginUser = (user, history, showToast) => {
  return {
    type: LOGIN_USER,
    payload: { user, history, showToast },
  };
};
// export const loginSuccess = user => {
//   return {
//     type: LOGIN_SUCCESS,
//     payload: user,
//   }
// }
export const loginSuccess = (user, token) => {
  return {
    type: LOGIN_SUCCESS,
    payload: {
      user,
      token
    }
  };
};

export const logoutUser = (history) => {
  return {
    type: LOGOUT_USER,
    payload: { history },
  };
};

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {},
  };
};

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};