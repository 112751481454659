import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from "reactstrap"
import { Link, Navigate } from "react-router-dom"
// Custom Scrollbar
import SimpleBar from "simplebar-react"

// import images
import servicesIcon1 from "../../assets/images/services-icon/01.png"
import servicesIcon2 from "../../assets/images/services-icon/02.png"
import servicesIcon3 from "../../assets/images/services-icon/03.png"
import servicesIcon4 from "../../assets/images/services-icon/04.png"
import user2 from "../../assets/images/users/user-2.jpg"
import user3 from "../../assets/images/users/user-3.jpg"
import user4 from "../../assets/images/users/user-4.jpg"
import user5 from "../../assets/images/users/user-5.jpg"
import user6 from "../../assets/images/users/user-6.jpg"
import smimg1 from "../../assets/images/small/img-1.jpg"
import smimg2 from "../../assets/images/small/img-2.jpg"

// Charts
import { useNavigate } from "react-router-dom"
import LineAreaChart from "../AllCharts/apex/lineareachart"
import RadialChart from "../AllCharts/apex/apexdonut"
import Apexdonut from "../AllCharts/apex/apexdonut1"
import SparkLine from "../AllCharts/sparkline/sparkline"
import SparkLine1 from "../AllCharts/sparkline/sparkline1"
import Salesdonut from "../AllCharts/apex/salesdonut"

import "chartist/dist/scss/chartist.scss"
import MiniStatCard from "../../components/Common/Ministat"
//i18n
import { withTranslation } from "react-i18next"
import { data } from "pages/AllCharts/chartjs/linechart"

import BannerCarosell from "./BannerCarosell"
import { post, del, get, put } from "../../helpers/api_helper"
import { GetLoggedInUser } from "helpers/backend_helper"

const Dashboard = props => {
  const [data, setData] = useState(null)
  const [bookings, setBookings] = useState(null)
  const [user, setUrl] = useState(GetLoggedInUser)
  const [drivers, setDrivers] = useState(null)
  const [lastMonthEarning, setlastMonthEarning] = useState(null)
  const [thisMonthEarning, setthisMonthEarning] = useState(null)
  const [vehicles, setVehicles] = useState(null)
  const [menu, setMenu] = useState(false)
  const [providerId, setProviderId] = useState(user?.provider_id || null)
  const toggle = () => {
    setMenu(!menu)
  }

  async function getData(id) {
    try {
      const response =
        id != null
          ? await get(`/dashboard/data?providerId=${providerId}`)
          : await get(`/dashboard/data`)
      // console.log("Dashboard Data:", response) // Debugging log
      setData(response)
    } catch (error) {
      console.error("Error fetching dashboard data:", error)
    }
  }



  useEffect(() => {
    if (providerId != undefined) getData(providerId) // Fetch data once providerId is set
    else
      getData()
  }, [providerId])


  function formatCurrencyETB(amount) {
    // Ensure the input is a number
    const numberAmount = parseFloat(amount);
    if (isNaN(numberAmount)) {
      return "Invalid amount";
    }

    // Format the number to Ethiopian Birr currency
    return `ETB ${numberAmount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
  }
  function calculatePercentageChange(data) {
    const lastMonthEarnings = data?.totalEarningsByLastMonth ?? 0;
    const thisMonthEarnings = data?.totalEarningsByThisMonth ?? 0;

    // Calculate the change
    const change = thisMonthEarnings - lastMonthEarnings;

    // Avoid division by zero
    if (lastMonthEarnings === 0) {
      return change > 0 ? "+100%" : change < 0 ? "-100%" : "0%";
    }

    // Calculate percentage change
    const percentageChange = (change / lastMonthEarnings) * 100;

    // Format the result
    return `${percentageChange > 0 ? "+" : ""}${percentageChange.toFixed(2)}%`;
  }


  document.title = "Dashboard | hearts - React Admin & Dashboard  "
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h6 className="page-title">Dashboard</h6>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    Welcome to hearts Dashboard
                  </li>
                </ol>
              </Col>

              <Col md="4">
                <div className="float-end d-none d-md-block"></div>
              </Col>
            </Row>
          </div>
          {user?.provider_id != null && (<Row>
            <MiniStatCard
              icon={servicesIcon1}
              title="Credits"
              value="12,000"
            // change="+ 12%"
            // changeDirection="up"
            // link="/bookings"
            />
            <hr />
          </Row>)}

          <Row>
            <Col xl={3} md={6}>
              <MiniStatCard
                icon={servicesIcon1}
                title="Bookings"
                value={data?.totalBookings || 0}
                // change="+ 12%"
                changeDirection="up"
                link="/bookings"
              />
            </Col>
            <Col xl={3} md={6}>
              <MiniStatCard
                icon={servicesIcon2}
                title="Earnings"
                positive={data?.totalEarningsByThisMonth ?? 0 > data?.totalEarningsByThisMonth ?? 0}
                change={calculatePercentageChange({ totalEarningsByLastMonth: data?.totalEarningsByLastMonth ?? 0, totalEarningsByThisMonth: data?.totalEarningsByThisMonth ?? 0 })}
                value={`${formatCurrencyETB(data?.totalEarningsByThisMonth ?? 0)}`}
                changeDirection="down"
                link="#"
              />
            </Col>



            <Col xl={3} md={6}>
              <MiniStatCard
                icon={servicesIcon4}
                title="Total Drivers"
                value={data?.totalDrivers ?? 0}
                // change="+ 84%"
                changeDirection="up"
                link="/drivers"
              />
            </Col>
            <Col xl={3} md={6}>
              <MiniStatCard
                icon={servicesIcon3}
                title="Total Vehicles"
                value={data?.totalVehicles ?? 0}
                changeDirection="up"
                link="/vehicles"
              />
            </Col>
            <hr />

          </Row>
          <br />
          <Row>
            <BannerCarosell />
          </Row>


          {/* activity list / steps esc */}
        </Container>
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(Dashboard)
