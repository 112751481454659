import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from "reactstrap"
import ReactPaginate from "react-paginate"
import { Link, useLocation, useNavigate } from "react-router-dom"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import CreateDriverModal from "./CreateDriverModal"
import { toast } from "react-toastify"
import { post, del, get, put } from "../../helpers/api_helper"
import SkeletonRow from "../../components/Common/skeletonRow"
import user2 from "../../assets/images/users/user-2.jpg"
import user3 from "../../assets/images/users/user-3.jpg"
import user4 from "../../assets/images/users/user-4.jpg"
import user5 from "../../assets/images/users/user-5.jpg"
import user6 from "../../assets/images/users/user-6.jpg"
import smimg1 from "../../assets/images/small/img-1.jpg"
import smimg2 from "../../assets/images/small/img-2.jpg"
import { GetClients, GetLoggedInUser } from "helpers/backend_helper"

function Clients() {
  const [clients, setClients] = useState([])
  const [singlebtn, setSinglebtn] = useState(false)
  const [searchTerm, setSearchTerm] = useState("")
  const [loading, setLoading] = useState(true)
  const [driverStatus, setDriverStatus] = useState(0)
  const [user, setUser] = useState(GetLoggedInUser)
  const [selectedItem, setSelectedItem] = useState([
    <span className="badge bg-primary badge-xl">All</span>,
    0,
  ])
  const [driverOnline, setDriverOnline] = useState(0)
  const [onlineDropdownOpen, setOnlineDropdownOpen] = useState(false)
  const [selectedOnlineItem, setSelectedOnlineItem] = useState([
    <span className="badge bg-primary badge-xl">All</span>,
    0,
  ])
  const [currentDriverPage, setCurrentDriverPage] = useState(1)
  const [totalDriverPages, setTotalDriverPages] = useState(1)
  const [ClientsPerPage, setClientsPerPage] = useState(12)
  const [all, setAll] = useState(false)
  useEffect(() => {
    const fetchClients = async () => {
      try {
        const authUser = GetLoggedInUser()
        if (authUser.roleId == "1") {
          const response = await GetClients({
            params: {
              page: currentDriverPage,
              limit: ClientsPerPage,
              all: all,
            },
          })
          setClients(response.data)
          setTotalDriverPages(response.totalPages)
        } else {
          // i dont know what to filter
          const response = await GetClients({
            params: {
              page: currentDriverPage,
              limit: ClientsPerPage,
              all: all,
            },
          })
          // const response = await  get(`/clients?page=${currentDriverPage}&limit=${ClientsPerPage}&all=${all}`);
          setClients(response.data)
          setTotalDriverPages(response.totalPages)
        }
      } catch (error) {
        console.error("Error fetching Clients:", error)
        toast.error("Error fetching Clients")
      } finally {
        setLoading(false)
      }
    }

    fetchClients()
  }, [currentDriverPage])

  const handlePageChange = pageNumber => {
    setCurrentDriverPage(pageNumber)
  }

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalType, setModalType] = useState("")

  const toggleModal = type => {
    setModalType(type)
    setIsModalOpen(!isModalOpen)
  }
  const navigate = useNavigate()
    
  if (user?.roleId !== "1") {
      navigate("/dashboard")
    }
  

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row className="relative  d-flex align-items-center justify-content-end">
            <Col md="4" className="mb-3 mb-md-0">
              <Dropdown
                isOpen={singlebtn}
                toggle={() => setSinglebtn(!singlebtn)}
              >
                <DropdownToggle className="btn btn-secondary" caret>
                  {selectedItem[0] || "Status"}{" "}
                  <i className="mdi mdi-chevron-down" />
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    onClick={() => {
                      setDriverStatus(0)
                      setSelectedItem(["All", 0])
                    }}
                  >
                    <span
                      className="badge bg-primary badge-xl"
                      style={{ fontSize: "1rem" }}
                    >
                      All
                    </span>
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      setDriverStatus(1)
                      setSelectedItem(["Active", 1])
                    }}
                  >
                    <span
                      className="badge bg-success"
                      style={{ fontSize: "1rem" }}
                    >
                      Active
                    </span>
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      setDriverStatus(2)
                      setSelectedItem(["Inactive", 2])
                    }}
                  >
                    <span
                      className="badge bg-danger"
                      style={{ fontSize: "1rem" }}
                    >
                      Inactive
                    </span>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </Col>

            <Col md="4" className="mb-3 mb-md-0">
              <Dropdown
                isOpen={onlineDropdownOpen}
                toggle={() => setOnlineDropdownOpen(!onlineDropdownOpen)}
              >
                <DropdownToggle className="btn btn-secondary" caret>
                  {selectedOnlineItem[0] || "Online Status"}{" "}
                  <i className="mdi mdi-chevron-down" />
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    onClick={() => {
                      setDriverOnline(0)
                      setSelectedOnlineItem(["All", 0])
                    }}
                  >
                    <span
                      className="badge bg-primary badge-xl"
                      style={{ fontSize: "1rem" }}
                    >
                      All
                    </span>
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      setDriverOnline(1)
                      setSelectedOnlineItem(["Online", 1])
                    }}
                  >
                    <span
                      className="badge bg-success"
                      style={{ fontSize: "1rem" }}
                    >
                      Online
                    </span>
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      setDriverOnline(2)
                      setSelectedOnlineItem(["Offline", 2])
                    }}
                  >
                    <span
                      className="badge bg-danger"
                      style={{ fontSize: "1rem" }}
                    >
                      Offline
                    </span>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </Col>

            <Col md="4">
              <form className="app-search d-block">
                <div className="position-relative">
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                  />
                  <span className="fa fa-search"></span>
                </div>
              </form>
            </Col>
          </Row>

          <Col>
            <Card>
              <CardBody>
                <h4 className="card-title mb-4">Clients</h4>

                <div className="table-responsive">
                  <table className="table table-hover table-centered table-nowrap mb-2">
                    <thead>
                      <tr>
                        <th scope="col">Phone Number</th>
                        <th scope="col">Name</th>
                        <th scope="col">Email</th>
                        <th scope="col">Requests</th>
                        {/* <th scope="col">Sex</th>
                        <th scope="col">Blood</th> */}
                        <th scope="col" colSpan="2">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <> {SkeletonRow({ numRows: 4, numColumns: 5 })} </>
                        
                      ) : (
                        <>
                          {clients &&
                            clients.map(driver => (
                              <DriverRow
                                key={driver.id}
                                id={driver.id}
                                provider={driver?.Request?.length}
                                phone_number={driver.phone_number}
                                username={driver.username}
                                name={driver.name}
                                email={driver.email}
                                photo={driver.photo}
                                is_active={driver?.age}
                                // online={driver.bloodGroup||"O" + driver.bloodSign|| "+"}
                                // status={driver.status}
                              />
                            ))}
                        </>
                      )}
                    </tbody>
                  </table>

                  <div className="d-flex justify-content-center">
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel="next >"
                      onPageChange={event => handlePageChange(event.selected)}
                      pageRangeDisplayed={5}
                      pageCount={totalDriverPages}
                      previousLabel="< previous"
                      renderOnZeroPageCount={null}
                      containerClassName="pagination"
                      pageClassName="page-item"
                      pageLinkClassName="page-link"
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      breakClassName="page-item"
                      breakLinkClassName="page-link"
                      activeClassName="active"
                    />
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Clients

const DriverRow = ({
  id,
  phone_number,
  provider,
  username,
  email,
  photo,
  is_active,
  online,
  rating,
  name,
}) => {
  return (
    <tr>
      <th scope="row">{phone_number}</th>
      <td>{name}</td>
      <td>{email}</td>
      <td>{provider}</td>
      <td>{is_active}</td>
      <td>
        <div>
          <Link
            to={`${location.pathname}/${id}`}
            className="btn btn-primary btn-sm"
          >
            <i className="mdi mdi-arrow-right h5"></i>
          </Link>
        </div>
      </td>
    </tr>
  )
}
