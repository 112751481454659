// import React, { useState, useEffect } from "react"
// import {
//   Container,
//   Row,
//   Col,
//   Card,
//   CardBody,
//   CardTitle,
//   CardSubtitle,
//   Form,
//   FormGroup,
//   Label,
//   Input,
//   InputGroup,
//   Button,
//   ButtonGroup,
// } from "reactstrap"
// import driverLocationIcon from "../../assets/images/driver_location.png"
// import PropTypes from "prop-types"
// import {
//   Map,
//   InfoWindow,
//   Marker,
//   MarkerF,
//   GoogleApiWrapper,
// } from "google-maps-react"
// import { post, del, get, put } from "../../helpers/api_helper"
// const LoadingContainer = () => <div>Loading...</div>
// import { useSocket } from "../../SocketContext"
// const DriverMap = ({ google }) => {
//   // Destructure pickup and dropoff locations from booking data
//   const [drivers, setDrivers] = useState(null)
//   const iconSize = { width: 32, height: 32 }
//   const [selectedPlace, setSelectedPlace] = useState(null)
//   // const [markers, setMarkers] = useState([])
//   const socket = useSocket()
//   function onMarkerClick(place) {
//     console.log("first")
//     setSelectedPlace(place)
//   }
//   const defaultCenter = {
//     // Define the default center location
//     lat: 9.007923,
//     lng: 38.767821,
//   }
//   useEffect(() => {
//     const fetchData = async () => {
//       const fetchedDrivers = await get("/drivers") // Call the fetchDrivers function
//       const authUser = JSON.parse(localStorage.getItem("authUser"))
//       if (authUser.roleId != "1") {
//         const filteredDrivers = await fetchedDrivers.filter(
//           driver =>
//             driver.providerId === authUser.provider_id &&
//             driver.is_active === true
//         )
//         setDrivers(filteredDrivers)
//       } else {
//         const filteredDrivers = await fetchedDrivers.filter(
//           driver => driver.is_active === true
//         )
//         setDrivers(filteredDrivers)
//       }
//     }

//     fetchData()
//   }, [])

//   // useEffect(() => {
//   //   const handlePositionChange = data => {
//   //     // console.log(data)
//   //     // console.log(driver_id)
//   //     if (data.id === driver_id) {
//   //       console.log("driver match:", data.positionChangeData)
//   //       setDriverLocation({
//   //         latitude: data.positionChangeData.latitude,
//   //         longitude: data.positionChangeData.longitude,
//   //       })
//   //       updateMarkers({
//   //         latitude: data.positionChangeData.latitude,
//   //         longitude: data.positionChangeData.longitude,
//   //       })
//   //     }
//   //   }

//   //   socket.on("position_change", handlePositionChange)

//   //   return () => {
//   //     socket.off("position_change", handlePositionChange)
//   //   }
//   // }, [drivers, socket, updateMarkers])

//   const markers = drivers.map(driver => {
//     const {
//       driver_location: { latitude, longitude },
//     } = driver

//     // Customize marker properties (optional)
//     // let markerIcon;
//     // if (driver.is_active) {
//     //   markerIcon = <CustomMarker>A</CustomMarker>; // Available driver icon
//     // } else {
//     //   markerIcon = <CustomMarker>U</CustomMarker>; // Unavailable driver icon
//     // }

//     return {
//       position: { lat: latitude, lng: longitude },
//       onClick: () => handleMarkerClick({ title: driver.name }),
//       // icon: markerIcon, // Use custom icon (optional)
//     }
//   })
//   const onLoad = marker => {
//     console.log("marker: ", marker)
//   }
//   // console.log(markers)
//   if (!markers) {
//     return <></>
//   }

//   return (
//     <React.Fragment>
//       <div className="page-content">
//         <div className="container-fluid">
//           <Card>
//             <CardBody>
//               <CardTitle className="h4">Markers</CardTitle>
//               <CardSubtitle className="mb-3">Request markers</CardSubtitle>
//               <div
//                 id="gmaps-markers"
//                 // className="gmaps"
//                 style={{ position: "relative", width: "100%", height: "60vh" }}
//               >
//                 <Map
//                   google={window.google}
//                   initialCenter={defaultCenter}
//                   zoom={13}
//                   style={{ width: "100%", height: "100%" }}
//                 >
//                   {markers.map(marker => (
//                     <Marker
//                       key={marker.position.lat}
//                       {...marker}
//                       icon={{
//                         url: driverLocationIcon,
//                         scaledSize: new window.google.maps.Size(
//                           iconSize.width,
//                           iconSize.height
//                         ),
//                       }}
//                     /> // Spread marker properties
//                   ))}
//                   {selectedPlace && (
//                     <InfoWindow
//                       position={{
//                         lat: selectedPlace.lat,
//                         lng: selectedPlace.lng,
//                       }}
//                     >
//                       <div>
//                         <h1>{selectedPlace.title}</h1>
//                       </div>
//                     </InfoWindow>
//                   )}
//                 </Map>
//               </div>
//             </CardBody>
//           </Card>
//         </div>
//       </div>
//     </React.Fragment>
//   )
// }

// DriverMap.propTypes = {
//   booking: PropTypes.shape({
//     pickup_location: PropTypes.shape({
//       latitude: PropTypes.number,
//       longitude: PropTypes.number,
//     }),
//     dropoff_location: PropTypes.shape({
//       latitude: PropTypes.number,
//       longitude: PropTypes.number,
//     }),
//   }),
//   google: PropTypes.object,
// }

// export default GoogleApiWrapper({
//   apiKey: "AIzaSyB0mM2GzFzyEynGun8ez0IKFvrStuYjOu0",
//   LoadingContainer: LoadingContainer,
//   v: "3",
// })(DriverMap)


import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { Map, Marker, InfoWindow, GoogleApiWrapper } from 'google-maps-react';
import { Card, CardBody, CardTitle, CardSubtitle } from 'reactstrap';
import {useSocket} from '../../SocketContext'; 
import driverLocationIcon from "../../assets/images/driver_location.png";// Assuming you have a custom hook for socket connection
import { get } from "../../helpers/api_helper";
import { GetDrivers, GetLoggedInUser } from 'helpers/backend_helper';
import { LoadingPage } from 'pages/Utility/loading';
const LoadingContainer = () => ( <LoadingPage />);
const DriverMap = ({ google }) => {
  const [drivers, setDrivers] = useState([]);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const mapRef = useRef(null); 
  const socket = useSocket();

  const defaultCenter = {
    lat: 9.007923,
    lng: 38.767821,
  };

  const fetchData = useCallback(async () => {
    try {
      const authUser = GetLoggedInUser();
      let fetchedDrivers = [];
      if (authUser.roleId !== '1') {
        fetchedDrivers = await get('/drivers', {
          params: { providerId: authUser.provider_id, is_active: true, all: true },
        });
      } else {
        fetchedDrivers = await GetDrivers({ params: { is_active: true, all: true } });
      }
      setDrivers(fetchedDrivers.data);
    } catch (error) {
      console.error('Error fetching drivers:', error);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    const handlePositionChange = (data) => {
      setDrivers((prevDrivers) =>
        prevDrivers.map((driver) =>
          driver.id === data.id ? { ...driver, driver_location: data.positionChangeData } : driver
        )
      );
    };

    socket.on('position_change', handlePositionChange);

    return () => {
      socket.off('position_change', handlePositionChange);
    };
  }, [socket]);

  const markers = drivers.map((driver) => {
    const { driver_location } = driver;

    return {
      position: { lat: driver_location.latitude, lng: driver_location.longitude },
      title: driver.name,
      driver_location,
      icon: driverLocationIcon,
    };
  });

  const handleMarkerClick = (marker) => {
    setSelectedPlace(marker);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Card>
            <CardBody>
              <CardTitle className="h4">Markers</CardTitle>
              <CardSubtitle className="mb-3">Request markers</CardSubtitle>
              <div
                id="gmaps-markers"
                style={{ position: 'relative', width: '100%', height: '60vh' }}
              >
                <Map
                  google={google}
                  ref={mapRef} // Reference to the map component
                  initialCenter={defaultCenter}
                  zoom={13}
                  style={{ width: '100%', height: '100%' }}
                >
                  {markers.map((marker, index) => (
                    <Marker
                      key={index}
                      position={marker.position}
                      title={marker.title}
                      icon={{
                        url: marker.icon,
                        scaledSize: new google.maps.Size(32, 32), // Ensure icon size is valid
                      }}
                      onClick={() => handleMarkerClick(marker)}
                    />
                  ))}
                  {selectedPlace && (
                    <InfoWindow position={selectedPlace.position} visible={true}>
                      <div>
                        <h1>{selectedPlace.title}</h1>
                        <p>Accuracy: {selectedPlace.driver_location.accuracy}</p>
                        <p>Altitude: {selectedPlace.driver_location.altitude}</p>
                        <p>Altitude Accuracy: {selectedPlace.driver_location.altitudeAccuracy}</p>
                        <p>Floor: {selectedPlace.driver_location.floor}</p>
                        <p>Heading: {selectedPlace.driver_location.heading}</p>
                        <p>Heading Accuracy: {selectedPlace.driver_location.headingAccuracy}</p>
                        <p>Position: {selectedPlace.driver_location.position}</p>
                        <p>Speed: {selectedPlace.driver_location.speed}</p>
                        <p>Speed Accuracy: {selectedPlace.driver_location.speedAccuracy}</p>
                        <p>Timestamp: {new Date(selectedPlace.driver_location.timestamp).toString()}</p>
                      </div>
                    </InfoWindow>
                  )}
                </Map>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    </React.Fragment>
  );
};

DriverMap.propTypes = {
  google: PropTypes.object,
};

export default
 GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  LoadingContainer: LoadingContainer,
  v: '3',
})
(DriverMap);
