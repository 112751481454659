import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes"
import { apiError, loginSuccess, logoutUserSuccess } from "./actions"

import { toast } from "react-toastify"
//Include Both Helper File with needed methods

import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import {
  postFakeLogin,
  postJwtLogin,
  postSocialLogin,
} from "../../../helpers/fakebackend_helper"

const fireBaseBackend = getFirebaseBackend()

const base_url = 'https://ride-haling-backend.onrender.com/api'
// const base_url = 'http://localhost:3001/api'
function* loginUser({ payload: { user, history, showToast } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(
        fireBaseBackend.loginUser,
        user.username,
        user.password
      );
      yield put(loginSuccess(response));
      showToast(response);
      history("/dashboard");
    } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      try {
        const response = yield call(
          postJwtLogin,
          `${base_url}/auth/login`,
          {
            email: user.username,
            password: user.password,
          }
        );
        console.log("login??");
          if (response.user.is_active) {
            localStorage.setItem("authUser", JSON.stringify(response.user));
            localStorage.setItem("token", JSON.stringify(response.token));
            localStorage.setItem('expiryDate', JSON.stringify(response.expiryDate));
            yield put(loginSuccess(response));
            showToast(response);
            history("/dashboard");
          } else {
            localStorage.setItem("authUser", JSON.stringify(response.user));
            localStorage.setItem("token", JSON.stringify(response.token));
            showToast(response);
            history("/inactive");
          }

      } catch (error) {
        yield put(apiError(response));
        showToast(response);
        console.log(error);
        yield put(apiError(error));
        // showToast(error);
        throw error;
      }
    } else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
      const response = yield call(postFakeLogin, {
        email: user.email,
        password: user.password,
      });
      localStorage.setItem("authUser", JSON.stringify(response));
      yield put(loginSuccess(response));
      showToast(response);
      history("/dashboard");
    }
  } catch (error) {
    console.log("error", error);
    yield put(apiError(error));
    showToast(error);
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser")

    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.logout)
      yield put(logoutUserSuccess(response))
    }
    history("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
