import React, { useEffect, useState } from "react"
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Container,
  Button,
  ButtonGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { post, del, get, put } from "../../helpers/api_helper"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { number } from "yup"
import user3 from "../../assets/images/users/user-3.jpg"
import car3 from "../../assets/images/driver_location.png"
import DriverCard from "./DriverCard"
import CreateMap from "./CreateBookingMap"
import {
  GetDrivers,
  GetLoggedInUser,
  GetProviderById,
  GetProviders,
} from "helpers/backend_helper"
function CreateBooking() {
  const [price, setprice] = useState(null)
  const [driver_id, setdriver_id] = useState("")
  const [selectedDriver, setSelectedDriver] = useState(null)
  const [selectedProvider, setSelectedProvider] = useState({})
  const [clientPhone, setClientPhone] = useState("")
  const [pickup, setpickup] = useState(null)
  const [dropoff, setdropoff] = useState(null)
  const [distance, set_distance] = useState("")
  const [duration, set_duration] = useState("")
  const [provider_id, setProvider_id] = useState("")
  const authUser = GetLoggedInUser()
  const [drivers, setdrivers] = useState([])
  const [isOpen, setIsOpen] = useState(true)
  const [isCreating, setIsCreating] = useState(false)
  const toggleList = e => {
    e.preventDefault()
    setIsOpen(!isOpen)
  }
  const navigate = useNavigate()
  const handleDriverSelect = (e, driver) => {
    e.preventDefault()
    setSelectedDriver(driver)
    setdriver_id(driver.id)
    toggleList(e)
  }

  // const [providers, setProviders] = useState([])

  async function getdriver() {
    const response = await GetDrivers({
      params: { providerId: authUser.provider_id, is_active: true, all: true },
    })
    const activeDrivers = response.data.filter(driver => driver.vehicle);
    setdrivers(activeDrivers);
  }
  //   else{
  //   const response = await GetDrivers({params: { providerId:authUser.provider_id, is_active:true, all:true, }});
  //   setdrivers(response.data)
  //   console.log("/////////////////////")
  // }

  async function getProvider() {
    const response = await GetProviderById(authUser.provider_id)
    // setProviders([response])
    if (response) {
      setSelectedProvider(response)
      setProvider_id(response.id)
    }
  }

  useEffect(() => {
    getdriver()
    getProvider()
    // getPickupLocation
  }, [])

  if (authUser.roleId == "1") {
    // navigate to /booking
    navigate("/bookings")
  }
  // useEffect(() => {
  //   console.log("prov", selectedProvider)
  //   console.log("pickup", pickup)
  //   console.log("dropoff", dropoff)
  //   console.log("duration", duration)
  //   console.log("distance", distance)
  // }, [selectedProvider, duration, distance])

  //   reminder move to helper
  function allFilled() {
    // console.log(
    //   "distance:",
    //   distance,
    //   "duration:",
    //   duration,
    //   "price:",
    //   price,
    //   "driver_id:",
    //   driver_id,
    //   "clientPhone:",
    //   clientPhone,
    //   "pickup:",
    //   pickup,
    //   "dropoff:",
    //   dropoff,
    //   "provider_id:",
    //   provider_id
    // )
    return (
      distance !== "" &&
      duration !== "" &&
      price !== "" &&
      driver_id !== "" &&
      clientPhone !== "" &&
      pickup !== "" &&
      dropoff !== "" &&
      provider_id !== ""
      //   providers
    )
  }
  function convertDistanceToKM(distanceString) {
    let [distance, unit] = distanceString.split(" ")
    let distanceNumber = parseFloat(distance)
    let conversionFactor = 1
    if (unit === "km") {
      conversionFactor = 1
    } else if (unit === "m") {
      conversionFactor = 0.001
    }
    let distanceInKM = distanceNumber * conversionFactor
    return distanceInKM
  }

  function getAmbulancePricing(driver) {
    // Extract the ambulance type ID from the driver's vehicle

    // Find the AmbulanceType associated with the selected driver's ambulance
    const selectedAmbulanceType = driver.vehicle.ambulance_type

    console.log("selectedAmbulanceType", selectedAmbulanceType)
    if (selectedAmbulanceType) {
      const farePerKm = selectedAmbulanceType.farePerKm
      const fixedPrice = selectedAmbulanceType.fixedPrice

      return { farePerKm, fixedPrice }
    } else {
      return { farePerKm: null, fixedPrice: null }
    }
  }

  async function createBookingNow(event) {
    try {
      setIsCreating(true)
      event.preventDefault()
      let _distance = convertDistanceToKM(distance)
      // console.log(_distance)
      const { farePerKm, fixedPrice } = getAmbulancePricing(
        selectedDriver
        // selectedProvider
      )
      console.log(
        "fairs",
        farePerKm,
        fixedPrice,
        selectedDriver,
        selectedProvider
      )
      let _price = _distance * farePerKm + fixedPrice
      console.log("calculated: price", _price)
      setprice(_price)
      setTimeout(async () => {
        if (allFilled()) {
          event.preventDefault()
          try {
            const response = await post("/bookings", {
              distance: _distance,
              duration,
              price: _price,
              driver_id,
              clientPhone,
              pickup,
              dropoff,
              fromApp: false,
              provider_id,
              status: "ACCEPTED",
            })
            console.log("response::", response.data)
            if (response.data) {
              toast.success("Booking created successfully!")
              navigate(`/bookings/${response.data.id}`)
            } else {
              console.error("Error creating Booking:", response.status)
              toast.error("Error creating Booking. Please try again.")
              setIsCreating(false)
            }
          } catch (error) {
            console.error("Network error:", error)
            toast.error("Network error. Please try again later.")
            setIsCreating(false)
          }
        } else {
          toast.error("Error fill all filleds. Please try again.")
          setIsCreating(false)
        }
      }, 2000)
    } catch (e) {
      setIsCreating(false)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row className="align-items-center row justify-content-end">
            <Col md="8" className="align-items-center row justify-content-end">
              <Breadcrumbs maintitle="hearts" title="Bookings" />
            </Col>
            <Col md='4'  className="relative  d-flex align-items-center justify-content-end"></Col>
          </Row>
          <CreateMap
            setpickup={setpickup}
            setdropoff={setdropoff}
            set_distance={set_distance}
            set_duration={set_duration}
          />
          <Row mb="2" className="align-items-center row justify-content-end">
            <form>
              <div className="mb-2">
                <label htmlFor="client_id" className="md-2 form-label">
                  Client Phone *
                </label>
                <div>
                  <span>+251</span>
                  <input
                    type="tel"
                    pattern="^[9|7]\d{8,9}$"
                    minLength="9"
                    maxLength="9"
                    className="form-control md-8"
                    id="client_id"
                    value={clientPhone}
                    onChange={e => setClientPhone(e.target.value)}
                  ></input>
                </div>
              </div>

              <div className="mb-3">
                <div className="d-flex justify-content-between align-items-center">
                  <button className="btn btn-secondary" onClick={toggleList}>
                    {selectedDriver ? (
                      <DriverCard driver={selectedDriver} />
                    ) : (
                      "Select a Driver"
                    )}
                  </button>
                  <button
                    className={`btn btn-${isOpen ? "danger" : "primary"}`}
                    onClick={toggleList}
                  >
                    {isOpen ? "Close" : "Open"}
                  </button>
                </div>
                {isOpen && (
                  <ul className="list-group mt-2">
                    {Array.isArray(drivers) &&
                      drivers.length > 0 &&
                      drivers.map(driver => (
                        <li
                          style={{
                            display: "flex",
                            width: "",
                            justifyContent: "space-between",
                          }}
                          key={driver.id}
                          className={`list-group-item ${
                            driver === selectedDriver ? "active" : ""
                          } mb-3`}
                          onClick={e => handleDriverSelect(e, driver)}
                        >
                          <DriverCard driver={driver} />
                        </li>
                      ))}
                  </ul>
                )}
              </div>

              <Row>
                <Button
                  color={!isCreating ? "primary" : "secondary"}
                  type="submit"
                  style={{ minHeight: "60px" }}
                  disabled={isCreating}
                  onClick={e => createBookingNow(e)}
                >
                  Add Booking
                </Button>
              </Row>
            </form>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CreateBooking
