import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from "reactstrap"
import { Link } from "react-router-dom"
import user3 from "../../assets/images/users/user-3.jpg"
import defaultVehicle from "../../assets/images/driver_location.png"

import SkeletonRow from "../../components/Common/skeletonRow"

import VehicleCard from "./VehicleCard"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import { toast } from "react-toastify"
import { post, del, get, put } from "../../helpers/api_helper"

import CreateVehicleModal from "./CreateVehicleModal"
import ReactPaginate from "react-paginate"
import { GetLoggedInUser, GetVehicles } from "helpers/backend_helper"
function Vehicles() {
  const [vehicles, setVehicles] = useState([])
  const [filteredvehicles, setFilteredVehicles] = useState([])
  const [singlebtn, setSinglebtn] = useState(false)
  const [searchTerm, setSearchTerm] = useState("")
  const [loading, setLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [itemsPerPage, setItemsPerPage] = useState(12)
  const [all, setAll] = useState(false)

  useEffect(() => {
    const fetchReview = async () => {
      try {
        const authUser = GetLoggedInUser()
        if (authUser.role_id !== "1") {
          const response = await GetVehicles({
            params: {
              page: currentPage,
              limit: itemsPerPage,
              all: all,
              providerId: authUser.provider_id,
            },
          })
          const { data, total_pages } = response
          setTotalPages(total_pages)
          setVehicles(data)
          setFilteredVehicles(data)
        } else {
          const response = await GetVehicles({
            params: { page: currentPage, limit: itemsPerPage, all: all },
          })
          const { data, total_pages } = response
          setTotalPages(total_pages)
          setVehicles(data)
          setFilteredVehicles(data)
        }
      } catch (error) {
        console.error("Error fetching Vehicles:", error)
      } finally {
        setLoading(false)
      }
    }

    fetchReview()
  }, [currentPage, itemsPerPage])

  const handlePageChange = pageNumber => {
    setCurrentPage(pageNumber)
  }

  // const filterVehicles = () => {
  //   return vehicles.filter(booking => {
  //     const searchRegex = new RegExp(searchTerm, "i")
  //     const isSearchTermMatch =
  //       searchTerm === "" ||
  //       searchRegex.test(booking.client?.username) ||
  //       searchRegex.test(booking.client?.email) ||
  //       searchRegex.test(booking.client?.phone_number) ||
  //       searchRegex.test(booking.driver?.name) ||
  //       searchRegex.test(booking.driver?.email) ||
  //       searchRegex.test(booking.driver?.phone_number) ||
  //       searchRegex.test(booking.provider?.name) ||
  //       searchRegex.test(booking.provider?.email) ||
  //       searchRegex.test(booking.provider?.phone_number)

  //   })
  // }

  // useEffect(() => {
  //   setFilteredVehicles(filterVehicles())
  // }, [vehicles, searchTerm])

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalType, setModalType] = useState("")

  const toggleModal = type => {
    setModalType(type)
    setIsModalOpen(!isModalOpen)
  }
  const handleVehicleNew = vehicle => {
    setVehicles([...vehicles, vehicle])
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <Breadcrumbs maintitle="hearts" title="Vehicles" />
            </Col>
            <Col md="8" className="d-flex justify-content-end">
              <div className="my-4 text-center">
                <Button
                  color="primary"
                  onClick={() => toggleModal("scroll")}
                  className="m-0"
                >
                  Create Vehicle
                </Button>
              </div>
              <CreateVehicleModal
                isModalOpen={isModalOpen}
                toggleModal={toggleModal}
                modalType={modalType}
                handleVehicleNew={handleVehicleNew}
              />
            </Col>
          </Row>

          <Row className="relative d-flex align-items-center justify-content-end">
            {/* <Col md="4" className="mb-3 mb-md-0">
              <Dropdown
                isOpen={singlebtn}
                toggle={() => setSinglebtn(!singlebtn)}
              >
                <DropdownToggle className="btn btn-secondary" caret>
                  {selectedItem[0] || "Status"}{" "}
                  <i className="mdi mdi-chevron-down" />
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    onClick={() => {
                      setProviderStatus(0)
                      setSelectedItem(["All", 0])
                    }}
                  >
                    <span
                      className="badge bg-primary badge-xl"
                      style={{ fontSize: "1rem" }}
                    >
                      All
                    </span>
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      setProviderStatus(1)
                      setSelectedItem(["Active", 1])
                    }}
                  >
                    <span
                      className="badge bg-success"
                      style={{ fontSize: "1rem" }}
                    >
                      Active
                    </span>
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      setProviderStatus(2)
                      setSelectedItem(["Inactive", 2])
                    }}
                  >
                    <span
                      className="badge bg-danger"
                      style={{ fontSize: "1rem" }}
                    >
                      Inactive
                    </span>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </Col> */}
            <Col md="4">
              <form
                className="app-search d-block "
                style={{ display: "block" }}
              >
                <div className="position-relative">
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                  />
                  <span className="fa fa-search"></span>
                </div>
              </form>
            </Col>
          </Row>
          <Row className="w-full">
            <Card className=" bg-secondary w-full">
              <CardBody className="">
                <h4 className="card-title mb-4">Vehicles</h4>

                {loading ? (
                  <table className="table table-hover table-centered table-nowrap ">
                    <tbody>

                    {SkeletonRow({ numRows: 4, numColumns: 1 })}
                    </tbody>
                  </table>
                ) : (
                  <>
                    {filteredvehicles &&
                      filteredvehicles.length &&
                      filteredvehicles.map(vehicle => (
                        <VehicleCard key={vehicle.id} vehicle={vehicle} />
                      ))}
                  </>
                )}
              </CardBody>
            </Card>
          </Row>

          <div className="d-flex justify-content-center">
            <ReactPaginate
              breakLabel="..."
              nextLabel="next >"
              onPageChange={event => handlePageChange(event.selected)}
              pageRangeDisplayed={6}
              pageCount={totalPages}
              previousLabel="< previous"
              renderOnZeroPageCount={null}
              containerClassName="pagination"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakClassName="page-item"
              breakLinkClassName="page-link"
              activeClassName="active"
            />
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Vehicles