import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { BrowserRouter } from "react-router-dom"
import "./i18n"
import { Provider } from "react-redux"
import store from "./store"

import MapProvider from "./MapProvider"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { SocketProvider } from "./SocketContext"
import SocketListener from "./SocketListener"
const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <SocketProvider>
        <SocketListener />
        <MapProvider>
        <ToastContainer />
          <App />
        </MapProvider>
      </SocketProvider>
    </BrowserRouter>
  </Provider>
)

serviceWorker.unregister()
