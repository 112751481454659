import { del, post } from 'helpers/api_helper';
import { GetBanners, GetLoggedInUser, GetProviders } from 'helpers/backend_helper';
import { Banners } from 'helpers/url_helper';
import { LoadingButton } from 'pages/Utility/loading';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify'; // Assuming you're using react-toastify for notifications
import { Button, FormGroup } from 'reactstrap';
import CreateBannerModal from './createBanner';

function Banner() {
    const [user, setUser] = useState(GetLoggedInUser);
    const [banners, setBanners] = useState([]);
    const [imageLoading, setImageLoading] = useState(false);
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [modalType, setModalType] = useState("")
    const toggleModal = type => {
        setModalType(type)
        setIsModalOpen(!isModalOpen)
      }


    useEffect(() => {
        if (user?.roleId !== "1") {
            console.log(user?.roleId);
        }
    }, [user, navigate]);

    const fetchBanners = async () => {
        try {
            setImageLoading(true)
            const response = await GetBanners({});
            const mappedBanners = response.map(banner => ({
                id: banner.id,
                src: banner.image || 'defaultBannerSrc',
                altText: banner.title || "Banner",
                caption: banner.title || "",
            }));
            setBanners(mappedBanners);
            setImageLoading(false)
        } catch (error) {
            setImageLoading(false)
            console.error("Error fetching banners:", error);
        }
    };

    useEffect(() => {
        fetchBanners();
    }, []);

    const handleBannerNew = banner => {
        setBanners([...banners, banner])
      }

    const handleDeleteBanner = async (id) => {
        try {
            const response = await del(`/banners/${id}`);
            if (response) {
                toast.success('Banner deleted successfully!');
                const res = await fetchBanners(); // Refresh banners after upload 
                setBanners(res.data); // Refresh banners after deletion
            } else {
                throw new Error('Delete failed');
            }
        } catch (error) {
            toast.error('Error deleting banner!');
            console.error('Error deleting banner:', error);
        }
    };

    return (
        <div style={{ paddingTop: '90px',  margin: '0 auto', textAlign: 'center' }}>
            <div className='mb-3' style={{display:"flex", justifyContent:"right", padding:"10px"}}>
            <Button color="primary" className="m-0"  onClick={() => toggleModal("scroll")} style={{  padding: '10px 15px' }}>
        Add Banner
                </Button>
            </div>
            <CreateBannerModal 
                isModalOpen={isModalOpen}
                toggleModal={toggleModal}
                modalType={modalType}
                handleBannerNew={handleBannerNew}
              />
            <h2 style={{ marginBottom: '20px' }}>Upload Banner Images</h2>
            {/* Additional form fields */}


            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-Around',  }}>
               {!imageLoading ? (banners?.map((banner) => (
                    <div key={banner.id} style={{ position: 'relative', margin: '10px', }}>
                        <img
                            src={banner.src}
                            alt={banner.altText}
                            style={{ width: '400px', height: '400px', objectFit: 'cover', borderRadius: '5px' }}
                        />
                        <button
                            onClick={() => handleDeleteBanner(banner.id)}
                            style={{
                                position: 'absolute',
                                top: '5px',
                                right: '5px',
                                backgroundColor: 'red',
                                color: 'white',
                                border: 'none',
                                borderRadius: '5px',
                                cursor: 'pointer',
                            }}
                        >
                            X
                        </button>
                    </div>
                ))):(<div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                    <LoadingButton />
                  </div>)
               }
                
 
            </div>
        </div>
    );
}

export default Banner;