import user3 from "../../assets/images/users/user-3.jpg"
import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Card,
  CardBody,
  CardTitle,
  CardText,
  CardLink,
} from "reactstrap"
import { useParams, useNavigate, Link } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { toast } from "react-toastify"
import Dropzone from "react-dropzone"
import { LoadingButton, LoadingPage } from "pages/Utility/loading"
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { DeleteClients, GetClientsById } from "helpers/backend_helper"
import { set } from "lodash"
function ClientsSlug() {
  const { id } = useParams()
  const navigate = useNavigate()
  const [clientData, setClientData] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [editedDriverData, setEditedDriverData] = useState(null)
const [deletion, setDeletion] = useState(false)
  

  useEffect(() => {
    // Fetch the driver data
    const fetchClientData = async () => {
      try {
        const data = await GetClientsById(id)
        // const data = await response
        setClientData(data)
        setEditedDriverData(data)
        console.log("////////////////////")
        console.log(data)
      } catch (error) {
        console.error("Error fetching client data:", error)
        toast.error("Error fetching client data")
      }
    }
    fetchClientData()
    // getVehicles()
  }, [id])

  const [isEditing, setIsEditing] = useState(false)

 
  const handleDelete = async (ev) => {
    try {
      ev.preventDefault()
      setIsLoading(true)
      const response = await DeleteClients(id, {})

      if (response.status >= 200 && response.status < 300) {
        toast.success("deleted")
        setTimeout(() => {
          navigate("/clients")
        }, 1000)
      } else {
        setIsLoading(false)
        toast.error("Error deleting client")
        throw new Error("Error deleting client: " + response.statusText)
      }
    } catch (error) {
      setIsLoading(false)
      toast.error("Error deleting client: ")
    }
  }
  const handleCancel = () => {
    setEditedDriverData(clientData)
    setIsEditing(false)
  }

  if (!clientData) {
      return (  <LoadingPage />)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
        <Row className="align-items-center row justify-content-start">
            <Col >
              <Breadcrumbs maintitle="hearts" title="Client" />
            </Col>
        </Row>
          <Row>
            <Col>
              <h2>Client Details</h2>
              {
!deletion ?(

          <div>
            <Button
            color="danger"
            onClick={() => {
              setDeletion(true)
            }}>
            Delete
          </Button>
            </div>

              ):
              (
                isLoading?
                  (<div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                    <LoadingButton />
                  </div>):
                  <div>
                  <p color=" danger "> Are you sure you want to delete this client? </p>
                <Button
                color="primary"
                onClick={(ev) => handleDelete(ev)}
              >
                Confirm Delete
              </Button>
                  </div>


              )
              }
              <Form>
              <div style={{display:"flex", justifyContent:"space-around"}}>
                    <div >
                      <Label for="photo">Photo</Label>

                        <div
                          style={{
                            backgroundImage: `url(${
                              editedDriverData?.photo ||
                              clientData.photo ||
                              user3
                            })`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            height: "200px",
                            width: "200px",
                            borderRadius: "50%",
                            border: "2px solid #ccc",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        />
                       <FormGroup className="mt-3">
                      <Label for="phone_number">Phone Number:</Label>
                      <Input
                        type="tel"
                        id="phone_number"
                        value={editedDriverData?.phone_number || ""}
                        onChange={e =>
                          setEditedDriverData({
                            ...editedDriverData,
                            phone_number: e.target.value,
                          })
                        }
                        disabled={true}
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label for="username">username:</Label>
                      <Input
                        type="text"
                        id="username"
                        value={editedDriverData?.username || ""}
                        onChange={e =>
                          setEditedDriverData({
                            ...editedDriverData,
                            username: e.target.value,
                          })
                        }
                        disabled={true}
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label for="name">Name:</Label>
                      <Input
                        type="text"
                        id="name"
                        value={editedDriverData?.name || ""}
                        onChange={e =>
                          setEditedDriverData({
                            ...editedDriverData,
                            name: e.target.value,
                          })
                        }
                        disabled={true}
                      />
                    </FormGroup>


                   <FormGroup>
                      <Label for="name">Age:</Label>
                      <Input
                        type="text"
                        id="name"
                        value={editedDriverData?.age }
                        onChange={e =>
                          setEditedDriverData({
                            ...editedDriverData,
                            name: e.target.value,
                          })
                        }
                        disabled={true}
                      />
                    </FormGroup>
                    
                    <FormGroup>
                      <Label for="name">Sex:</Label>
                      <Input
                        type="text"
                        id="name"
                        value={editedDriverData?.sex }
                        onChange={e =>
                          setEditedDriverData({
                            ...editedDriverData,
                            name: e.target.value,
                          })
                        }
                        disabled={true}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="name">Trips:</Label>
                      <Input
                        type="text"
                        id="name"
                        value={editedDriverData?.trips }
                        onChange={e =>
                          setEditedDriverData({
                            ...editedDriverData,
                            name: e.target.value,
                          })
                        }
                        disabled={true}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="name">Votes:</Label>
                      <Input
                        type="text"
                        id="name"
                        value={editedDriverData?.votes }
                        onChange={e =>
                          setEditedDriverData({
                            ...editedDriverData,
                            name: e.target.value,
                          })
                        }
                        disabled={true}
                      />
                    </FormGroup>
                    </div>
                    
                    <div>
                      <Label for="idproof">ID IdPhoto</Label>
                        <div
                          style={{
                            backgroundImage: `url(${
                              editedDriverData?.IdPhoto ||
                              clientData.IdPhoto ||
                              user3
                            })`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            height: "200px",
                            width: "200px",
                            borderRadius: "10px",
                            border: "2px solid #ccc",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        />
                                            <FormGroup>
                      <Label for="name" className="mt-3">Weight:</Label>
                      <Input
                        type="text"
                        id="name"
                        value={editedDriverData?.weight }
                        onChange={e =>
                          setEditedDriverData({
                            ...editedDriverData,
                            name: e.target.value,
                          })
                        }
                        disabled={true}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="name">Height:</Label>
                      <Input
                        type="text"
                        id="name"
                        value={editedDriverData?.height }
                        onChange={e =>
                          setEditedDriverData({
                            ...editedDriverData,
                            name: e.target.value,
                          })
                        }
                        disabled={true}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="name">Blood:</Label>
                      <Input
                        type="text"
                        id="name"
                        value={editedDriverData?.bloodGroup + editedDriverData?.bloodSign }
                        onChange={e =>
                          setEditedDriverData({
                            ...editedDriverData,
                            name: e.target.value,
                          })
                        }
                        disabled={true}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="name">Emergency Contact:</Label>
                      <Input
                        type="text"
                        id="name"
                        value={editedDriverData?.emergencyContact }
                        onChange={e =>
                          setEditedDriverData({
                            ...editedDriverData,
                            name: e.target.value,
                          })
                        }
                        disabled={true}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="name">Permanent Residency:</Label>
                      <Input
                        type="text"
                        id="name"
                        value={editedDriverData?.permanentResidency }
                        onChange={e =>
                          setEditedDriverData({
                            ...editedDriverData,
                            name: e.target.value,
                          })
                        }
                        disabled={true}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="name">BMI:</Label>
                      <Input
                        type="text"
                        id="name"
                        value={editedDriverData?.BMI }
                        onChange={e =>
                          setEditedDriverData({
                            ...editedDriverData,
                            name: e.target.value,
                          })
                        }
                        disabled={true}
                      />
                    </FormGroup>
                    </div>

              </div>

                <Row>

                </Row>
              </Form>
            </Col>
          </Row>
          <br />
          {/* vehicle /  */}
          <Row>
            <Col md={4}>
              <Card>
                <CardBody>
                  <CardTitle tag="h5">Request Details</CardTitle>
                  <CardText>
                  <Carousel>
                  {clientData.Request?.length && clientData.Request.map(
                      (request)=>(                 
                    <div style={{ display: "flex", flexDirection: "column", minHeight: "250px", alignItems:"start"}}>
                        <div>Price: {request.price}</div>
                        <div>
                          Destance: {request.distance}
                        </div>
                        <div>
                          Duration: {request.duration}
                        </div>
                        <div>
                          Status: {request.status}
                        </div>
                        <div>
                          Created At: {request.createdAt}
                        </div>
                      </div>
                      )
                  )}
                  </Carousel>
                  </CardText>
                </CardBody>
              </Card>
            </Col>
            <Col md={4}>
              <Card>
                <CardBody>
                  <CardTitle tag="h5">Payment Details</CardTitle>
                  <CardText>
                  <Carousel>
                  {clientData.Payment?.length && clientData.Payment.map(
                      (request)=>(                 
                    <div style={{ display: "flex", flexDirection: "column", minHeight: "250px", alignItems:"start"}}>
                        <div>Amount: {request.amount}</div>
                        <div>
                          Pyment Method: {request.method}
                        </div>
                        <div>
                          Tax: {request.tx_ref}
                        </div>
                        <div>
                          Status: {request.status}
                        </div>
                        <div>
                          Created At: {request.createdAt}
                        </div>
                      </div>
                      )
                  )}
                  </Carousel>
                  </CardText>
                </CardBody>
              </Card>
            </Col>
            <Col md={4}>
              <Card>
                {/* <CardBody>
                  <CardTitle tag="h5">Location</CardTitle>
                  <CardText>
                    Latitude: {clientData.driver_location.latitude}
                    <br />
                    Longitude: {clientData.driver_location.longitude}
                    <br />
                    Accuracy: {clientData.driver_location.accuracy}
                  </CardText>
                </CardBody> */}
              </Card>
            </Col>

            <Col md={4}>
              {/* <Card>
                <CardBody>
                  <CardTitle tag="h5">Provider</CardTitle>
                  <CardText>
                    provider: {clientData?.Provider?.name}
                    <br />
                    <Link to={`/providers/${clientData?.Provider?.id}`}>
                      view provider details
                    </Link>
                  </CardText>
                </CardBody>
              </Card> */}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ClientsSlug
