import React from "react"
import { Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"
import user3 from "../../assets/images/users/user-3.jpg"

const ReviewCard = ({ review }) => {
  const totalStars = 5

  // Function to format the date
  const formatDate = dateString => {
    const options = { year: "numeric", month: "long", day: "numeric" }
    return new Date(dateString).toLocaleDateString(undefined, options)
  }

  return (
    <Card
      className="mb-3"
      style={{
        height: "200px",
        width: "650px",
        marginLeft: "0",
        marginRight: "auto",
      }}
    >
      <CardBody>
        {/* Review Title */}
        <h5 className="fw-bold mb-2">Client Review</h5>

        {/* Star Rating */}
        <div className="text-warning mb-3">
          {Array.from({ length: totalStars }, (_, index) => (
            <span
              key={index}
              className={index < review.rating ? "fw-bold" : ""}
            >
              {index < review.rating ? "★" : "☆"}
            </span>
          ))}
        </div>

        {/* Reviewer Information */}
        <div className="d-flex justify-content-between mb-3">
          <div className="d-flex align-items-center">
            <img
              src={review?.client?.photo || user3}
              alt="Client Avatar"
              className="avatar-sm rounded-circle me-2"
              onError={e => {
                e.target.src = user3
              }}
            />
            <div>
              <h6 className="mb-0">{review.client?.name}</h6>
              <h6 className="mb-0">{review.client?.phone_number}</h6>
              <small className="text-muted">
                {formatDate(review.client?.createdAt)}
              </small>{" "}
              {/* Updated line */}
            </div>
          </div>
          <Link
            to={`/drivers/${review.driver?.id}`}
            className="text-decoration-none"
          >
            <div className="d-flex align-items-center">
              <img
                src={review.driver?.photo || user3}
                onError={e => {
                  e.target.src = user3
                }}
                alt="Driver Avatar"
                className="avatar-sm rounded-circle me-2"
              />
              <div>
                <h6 className="mb-0">{review.driver?.username}</h6>
                <small className="text-muted">Driver</small>
              </div>
            </div>
          </Link>
        </div>

        {/* Review Body */}
        <p className="text-muted mb-3">{review.comment}</p>

        {/* Review Rating and Provider Info */}
        <div className="d-flex align-items-center">
          <div className="me-2">
            <span className="fw-bold">{review.rating}</span>/{totalStars}
          </div>
          <div>
            <small className="text-muted">
              {review.Request?.provider?.name}
            </small>
          </div>
        </div>
      </CardBody>
    </Card>
  )
}

export default ReviewCard
