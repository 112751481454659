import React, { useState, useEffect } from "react"
import {
  Container,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
} from "reactstrap"
import PropTypes from "prop-types"
import {
  GoogleMap,
  Marker,
  DirectionsRenderer,
  InfoWindow,
  useJsApiLoader,
} from "@react-google-maps/api"
import pickupIcon from "../../assets/images/pickup-icon.png"
import dropoffIcon from "../../assets/images/dropoff-icon.png"
import driverLocationIcon from "../../assets/images/driver_location.png"
import { get } from "../../helpers/api_helper"
import { useSocket } from "../../SocketContext"
const defaultCenter = { lat: 9.007923, lng: 38.767821 }


const MiniMap = ({ booking }) => {
  const { pickup_location, dropoff_location, driver, driver_id } = booking || {}
  const [driverLocation, setDriverLocation] = useState(null)
  const driverLocationId = driver?.driver_location_id
  const [map, setMap] = useState(null)
  const [directions, setDirections] = useState(null)
  const [ShowDirections, setShowDirections] = useState(true)
  const [selectedPlace, setSelectedPlace] = useState(null)

  const [markers, setMarkers] = useState([])
  const socket = useSocket()

  // const { isLoaded, loadError } = useJsApiLoader({
  //   googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  //   libraries: ["places", "geometry"],
  // })
  function togleShowDirections(e) {
    setShowDirections(!ShowDirections)
  }

  useEffect(() => {
    const fetchDriverLocation = async () => {
      try {
        const response = await get(`/locations/${driverLocationId}`)
        setDriverLocation(response)
        updateMarkers(response)
      } catch (error) {
        console.error("Error fetching driver location:", error)
      }
    }
    if (driverLocationId) {
      fetchDriverLocation()
    }
  }, [driverLocationId])


  const updateMarkers = location => {
    setMarkers(prevMarkers => [
      {
        position: {
          lat: pickup_location.latitude,
          lng: pickup_location.longitude,
        },
        title: "Pickup Location",
        icon: pickupIcon,
      },
      {
        position: {
          lat: location?.latitude || 0,
          lng: location?.longitude || 0,
        },
        title: "Driver Location",
        icon: driverLocationIcon,
      },
      {
        position: {
          lat: dropoff_location.latitude,
          lng: dropoff_location.longitude,
        },
        title: "Dropoff Location",
        icon: dropoffIcon,
      },
    ])
  }
  useEffect(() => {

    const handlePositionChange = data => {
      // console.log(data)
      // console.log(driver_id)
      if (data.id === driver_id) {
        console.log("driver match:", data.positionChangeData)
        setDriverLocation({
          latitude: data.positionChangeData.latitude,
          longitude: data.positionChangeData.longitude,
        })
        updateMarkers({
          latitude: data.positionChangeData.latitude,
          longitude: data.positionChangeData.longitude,
        })
      }
    }

    socket.on("position_change", handlePositionChange)

    return () => {
      socket.off("position_change", handlePositionChange)
    }
  }, [driverLocationId, socket, updateMarkers])

  useEffect(() => {
    if (pickup_location && dropoff_location && driverLocation) {
      const directionsService = new window.google.maps.DirectionsService()
      directionsService.route(
        {
          origin: {
            lat: driverLocation.latitude,
            lng: driverLocation.longitude,
          },
          destination: {
            lat: dropoff_location.latitude,
            lng: dropoff_location.longitude,
          },
          waypoints: [
            {
              location: {
                lat: pickup_location.latitude,
                lng: pickup_location.longitude,
              },
            },
          ],
          travelMode: window.google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            setDirections(result)
          } else {
            console.error(`Error fetching directions: ${status}`)
          }
        }
      )
    }
  }, [pickup_location, dropoff_location, driverLocation])

  const onMapLoad = map => {
    setMap(map)
  }

  const iconSize = { width: 32, height: 32 }



  const handleMarkerClick = marker => {
    setSelectedPlace(marker)
  }

  // if (loadError) return <div>Error loading Google Maps API</div>
  // if (!isLoaded) return <div>Loading...</div>

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Card>
            <CardBody>
              <CardTitle className="h4">Markers</CardTitle>
              <CardSubtitle className="mb-3">
                Request markers{" "}
                <Button color="primary" onClick={togleShowDirections}>
                  {!ShowDirections ? "Show directions" : "Hide directions"}
                </Button>
              </CardSubtitle>
              <div style={{ height: "400px" }}>
                <GoogleMap
                  mapContainerStyle={{ height: "100%" }}
                  center={defaultCenter}
                  zoom={14}
                  options={{
                    gestureHandling: true,
                    zoomControl: true,
                    streetViewControl: false,
                    mapTypeControl: false,
                    fullscreenControl: true,
                  }}
                  onLoad={onMapLoad}
                >
                  {markers.map((marker, index) => (
                    <Marker
                      clickable={true}
                      label={marker.title}
                      zIndex={100}
                      key={index}
                      position={marker.position}
                      title={marker.title}
                      onClick={() => handleMarkerClick(marker)}
                      icon={{
                        url: marker.icon,
                        scaledSize: new window.google.maps.Size(
                          iconSize.width,
                          iconSize.height
                        ),
                      }}
                    />
                  ))}
                  {selectedPlace && (
                    <InfoWindow position={selectedPlace.position}>
                      <div>
                        <h1>{selectedPlace.title}</h1>
                      </div>
                    </InfoWindow>
                  )}
                  {ShowDirections && directions && (
                    <DirectionsRenderer
                      directions={directions}
                      options={{
                        polylineOptions: {
                          strokeColor: "#FF0000",
                          strokeOpacity: 0.8,
                          strokeWeight: 5,
                        },
                        markerOptions: {
                          // Specify separate icons for each point
                          icons: [
                            {
                              icon: {
                                url: pickupIcon,
                                scaledSize: new window.google.maps.Size(
                                  iconSize.width,
                                  iconSize.height
                                ),
                              },
                              position: 0, // Set the position for the pickup icon
                            },
                            {
                              icon: {
                                url: driverLocationIcon,
                                scaledSize: new window.google.maps.Size(
                                  iconSize.width,
                                  iconSize.height
                                ),
                              },
                              position: 1, // Set the position for the driver location icon
                            },
                            {
                              icon: {
                                url: dropoffIcon,
                                scaledSize: new window.google.maps.Size(
                                  iconSize.width,
                                  iconSize.height
                                ),
                              },
                              position: 2, // Set the position for the dropoff icon
                            },
                          ],
                        },
                      }}
                    />
                  )}
                </GoogleMap>
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
    </>
  )
}

MiniMap.propTypes = {
  booking: PropTypes.shape({
    pickup_location: PropTypes.shape({
      latitude: PropTypes.number,
      longitude: PropTypes.number,
    }),
    dropoff_location: PropTypes.shape({
      latitude: PropTypes.number,
      longitude: PropTypes.number,
    }),
    driver: PropTypes.shape({
      driver_location_id: PropTypes.string,
    }),
  }),
}

export default MiniMap

// import React, { useState, useEffect, useCallback } from "react"
// import {
//   GoogleMap,
//   Marker,
//   DirectionsRenderer,
//   InfoWindow,
// } from "@react-google-maps/api"
// import pickupIcon from "../../assets/images/pickup-icon.png"
// import dropoffIcon from "../../assets/images/dropoff-icon.png"
// import driverLocationIcon from "../../assets/images/driver_location.png"
// import { get } from "../../helpers/api_helper"
// import { useSocket } from "../../SocketContext" // Assuming you have a custom hook for socket

// const MiniMap = ({ booking }) => {
//   const { pickup_location, dropoff_location, driver, driver_id } = booking || {}
//   const [driverLocation, setDriverLocation] = useState(null)
//   const driverLocationId = driver?.driver_location_id
//   const [map, setMap] = useState(null)
//   const [directions, setDirections] = useState(null)
//   const [showDirections, setShowDirections] = useState(true)
//   const [selectedPlace, setSelectedPlace] = useState(null)
//   const [markers, setMarkers] = useState([])
//   const socket = useSocket()

//   const fetchDriverLocation = useCallback(async () => {
//     try {
//       const response = await get(`/locations/${driverLocationId}`)
//       // dropoff_location.latitude  =9.0350133
//       // dropoff_location.longitude  = 38.76236
//       setDriverLocation(response)
//       updateMarkers(response)
//     } catch (error) {
//       console.error("Error fetching driver location:", error)
//     }
//   }, [driverLocationId])

//   useEffect(() => {
//     if (driverLocationId) {
//       fetchDriverLocation()
//     }
//   }, [driverLocationId, fetchDriverLocation])

//   const updateMarkers = useCallback(
//     location => {
//       console.log("first")
//       setMarkers([
//         {
//           position: {
//             lat: pickup_location.latitude,
//             lng: pickup_location.longitude,
//           },
//           title: "Pickup Location",
//           icon: pickupIcon,
//         },
//         {
//           position: {
//             lat: location?.latitude || 0,
//             lng: location?.longitude || 0,
//           },
//           title: "Driver Location",
//           icon: driverLocationIcon,
//         },
//         {
//           position: {
//             lat: dropoff_location.latitude,
//             lng: dropoff_location.longitude,
//           },
//           title: "Dropoff Location",
//           icon: dropoffIcon,
//         },
//       ])
//     },
//     [pickup_location, dropoff_location]
//   )

//   useEffect(() => {
//     const handlePositionChange = data => {
//       if (data.id === driver_id) {
//         console.log("match", data)
//         const newLocation = { ...data.positionChangeData }
//         setDriverLocation(newLocation)
//         updateMarkers(newLocation)
//       }
//     }

//     socket.on("position_change", handlePositionChange)

//     return () => {
//       socket.off("position_change", handlePositionChange)
//     }
//   }, [driver_id, socket, updateMarkers])

//   useEffect(() => {
//     if (pickup_location && dropoff_location && driverLocation) {
//       const directionsService = new window.google.maps.DirectionsService()
//       directionsService.route(
//         {
//           origin: {
//             lat: driverLocation.latitude,
//             lng: driverLocation.longitude,
//           },
//           destination: {
//             lat: dropoff_location.latitude,
//             lng: dropoff_location.longitude,
//           },
//           waypoints: [
//             {
//               location: {
//                 lat: pickup_location.latitude,
//                 lng: pickup_location.longitude,
//               },
//             },
//           ],
//           travelMode: window.google.maps.TravelMode.DRIVING,
//         },
//         (result, status) => {
//           if (status === window.google.maps.DirectionsStatus.OK) {
//             setDirections(result)
//           } else {
//             console.error(`Error fetching directions: ${status}`)
//           }
//         }
//       )
//     }
//   }, [pickup_location, dropoff_location, driverLocation])

//   const handleMarkerClick = marker => {
//     setSelectedPlace(marker)
//   }

//   const iconSize = { width: 32, height: 32 }

//   return (
//     <div style={{ height: "400px" }}>
//       <GoogleMap
//         mapContainerStyle={{ height: "100%", width: "100%" }}
//         center={{
//           lat: pickup_location?.latitude || 0,
//           lng: pickup_location?.longitude || 0,
//         }}
//         zoom={14}
//         onLoad={map => setMap(map)}
//       >
//         {markers.map((marker, index) => (
//           <Marker
//             key={index}
//             position={marker.position}
//             title={marker.title}
//             onClick={() => handleMarkerClick(marker)}
//             icon={{
//               url: marker.icon,
//               scaledSize: new window.google.maps.Size(
//                 iconSize.width,
//                 iconSize.height
//               ),
//               labelOrigin: new window.google.maps.Point(16, -8),
//             }}
//             label={{
//               text: marker.title,
//               color: "black",
//               fontSize: "14px",
//               fontWeight: "bold",
//             }}
//           />
//         ))}
//         {selectedPlace && (
//           <InfoWindow position={selectedPlace.position}>
//             <div>
//               <h4>{selectedPlace.title}</h4>
//               {selectedPlace.title === "Driver Location" && (
//                 <div>
//                   <p>Accuracy: {driverLocation.accuracy}</p>
//                   <p>Speed: {driverLocation.speed}</p>
//                   <p>
//                     Timestamp:{" "}
//                     {new Date(driverLocation.timestamp).toLocaleString()}
//                   </p>
//                 </div>
//               )}
//             </div>
//           </InfoWindow>
//         )}
//         {showDirections && directions && (
//           <DirectionsRenderer
//             directions={directions}
//             options={{
//               polylineOptions: {
//                 strokeColor: "#FF0000",
//                 strokeOpacity: 0.8,
//                 strokeWeight: 5,
//               },
//             }}
//           />
//         )}
//       </GoogleMap>
//     </div>
//   )
// }

// export default MiniMap
