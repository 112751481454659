import { initializeApp } from "firebase/app"
import { getStorage } from "firebase/storage"

const firebaseConfig = {
  apiKey: "AIzaSyCYrRjlgzBoc2-BynjzVq0s1vHNPOlmqNQ",
  authDomain: "hearts-admin-panel.firebaseapp.com",
  projectId: "hearts-admin-panel",
  storageBucket: "hearts-admin-panel.appspot.com",
  messagingSenderId: "306184201330",
  appId: "1:306184201330:web:508a49279a28a63d658eeb",
}

const app = initializeApp(firebaseConfig)
const storage = getStorage(app)

export { app, storage }
