import axios from "axios"
import { post, del, get, put } from "./api_helper"
import * as url from "./url_helper"

// Gets the logged in user data from local session
const GetLoggedInUser = () => {
  const user = localStorage.getItem("authUser")
  if (user) return JSON.parse(user)
  return null
}

//is user is logged in
const IsUserAuthenticated = () => {
  return GetLoggedInUser() !== null
}

function Login(data) {
  return post(url.LOGIN, data)
}

function Logout() {
  localStorage.removeItem("authUser")
  window.location.href = "/auth"
  return true
}

// providers
function GetProviders(config) {
  return get(url.providers, config)
}

function GetProviderById(id, config) {
  return get(url.GET_PROVIDER_BY_ID(id), config)
}

function AddProvider(data, config) {
  return post(url.providers, data, config)
}

function UpdateProvider(id, data, config) {
  return put(url.GET_PROVIDER_BY_ID(id), data, config)
}

function DeleteProvider(id, config) {
  return del(url.GET_PROVIDER_BY_ID(id), config)
}

// drivers
function GetDrivers(config) {
  return get(url.Drivers, config)
}

function GetDriverById(id, config) {
  return get(url.GET_DRIVER_BY_ID(id), config)
}

function AddDriver(data, config) {
  return post(url.Drivers, data, config)
}

function UpdateDriver(id, data, config) {
  return put(url.GET_DRIVER_BY_ID(id), data, config)
}
function UpdateDriversVehicle(id, data, config) {
  return put(url.CHANGE_DRIVER_VEHICLE(id), data, config)
}

function ChangeDriverVehicle(id, data, config) {
  return put(url.CHANGE_DRIVER_VEHICLE(id), data, config)
}

function DeleteDriver(id, config) {
  return del(url.GET_DRIVER_BY_ID(id), config)
}

// managers
function GetManagers(config) {
  return get(url.Managers, config)
}

function GetManagerById(id, config) {
  return get(url.GET_MANAGER_BY_ID(id), config)
}

function AddManager(data, config) {
  return post(url.Managers, data, config)
}

function UpdateManager(id, data, config) {
  return put(url.GET_MANAGER_BY_ID(id), data, config)
}

function DeleteManager(id, config) {
  return del(url.GET_MANAGER_BY_ID(id), config)
}

function GetClients(config) {
  return get(url.Clients, config)
}

function GetClientsById(id, config) {
  return get(url.GET_CLIENT_BY_ID(id), config)
}

function AddClients(data, config) {
  return post(url.Clients, data, config)
}

function UpdateClients(id, data, config) {
  return put(url.GET_CLIENT_BY_ID(id), data, config)
}

function DeleteClients(id, config) {
  return del(url.GET_CLIENT_BY_ID(id), config)
}
// bookings
function GetBookings(config) {
  return get(url.Bookings, config)
}

function GetBookingById(id, config) {
  return get(url.GET_BOOKING_BY_ID(id), config)
}

function AddBooking(data, config) {
  return post(url.Bookings, data, config)
}

function UpdateBooking(id, data, config) {
  return put(url.GET_BOOKING_BY_ID(id), data, config)
}

function ChangeBookingDriver(id, data, config) {
  return put(url.CHANGE_BOOKING_DRIVER(id), data, config)
}

function DeleteBooking(id, config) {
  return del(url.GET_BOOKING_BY_ID(id), config)
}

// vehicles
function GetVehicles(config) {
  return get(url.Vehicles, config)
}

function GetVehicleById(id, config) {
  return get(url.GET_VEHICLE_BY_ID(id), config)
}

function AddVehicle(data, config) {
  return post(url.Vehicles, data, config)
}

function UpdateVehicle(id, data, config) {
  return put(url.GET_VEHICLE_BY_ID(id), data, config)
}

function DeleteVehicle(id, config) {
  return del(url.GET_VEHICLE_BY_ID(id), config)
}

// reviews
function GetReviews(config) {
  return get(url.Reviews, config)
}

function GetReviewById(id, config) {
  return get(url.GET_REVIEW_BY_ID(id), config)
}

function AddReview(data, config) {
  return post(url.Reviews, data, config)
}

function UpdateReview(id, data, config) {
  return put(url.GET_REVIEW_BY_ID(id), data, config)
}

function DeleteReview(id, config) {
  return del(url.GET_REVIEW_BY_ID(id), config)
}
//
function GetBanners(config) {
  return get(url.Banners, config)
}
//

function GetComplaints(config) {
  return get(url.Complaints, config)
}

function GetComplaintById(id, config) {
  return get(url.GET_COMPLAINT_BY_ID(id), config)
}

function GetAppInformation(config) {
  return get(url.AppInformation, config)
}
function UpdateAppInformation(data, config) {
  return post(url.AppInformation, data, config)
}

export {
  GetLoggedInUser,
  IsUserAuthenticated,
  Login,
  Logout,
  GetProviders,
  GetProviderById,
  AddProvider,
  UpdateProvider,
  UpdateDriversVehicle,
  DeleteProvider,
  GetDrivers,
  GetDriverById,
  AddDriver,
  UpdateDriver,
  DeleteDriver,
  GetManagers,
  GetManagerById,
  AddManager,
  UpdateManager,
  DeleteManager,
  GetBookings,
  GetBookingById,
  ChangeBookingDriver,
  ChangeDriverVehicle,
  AddBooking,
  UpdateBooking,
  DeleteBooking,
  GetVehicles,
  GetVehicleById,
  AddVehicle,
  UpdateVehicle,
  DeleteVehicle,
  GetReviews,
  GetReviewById,
  AddReview,
  UpdateReview,
  DeleteReview,
  GetClients,
  GetClientsById,
  AddClients,
  UpdateClients,
  DeleteClients,
  GetBanners,
  GetComplaints,
  GetComplaintById,
  GetAppInformation,
  UpdateAppInformation,
}
