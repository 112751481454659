import React from "react"
import { Row, Col, Button } from "reactstrap"
import { Link } from "react-router-dom"
import user3 from "../../assets/images/users/user-3.jpg"
import defaultVehicle from "../../assets/images/driver_location.png"

const VehicleCard = ({ vehicle }) => {
  return (
    <div className="card mb-3">
      <div className="card-body">
        <Row>
          <Col xl={8} md={8}>
            <div className="d-flex align-items-center">
              <div>
                <h5 className="card-title">{vehicle.car_name}</h5>
                <span className="card-text d-flex align-items-center">
                  Plate Number: {vehicle.plate_number}
                  <Button
                    color={vehicle.isActive ? "success" : "danger"}
                    className="btn-sm ms-2"
                  >
                    {vehicle.isActive ? "Active" : "Active"}
                  </Button>
                </span>
                <br />
                <span>Type: {vehicle.type}</span>
              </div>
            </div>
            {vehicle?.Driver ? (
              <Link to={`/drivers/${vehicle.Driver.id}`}>
                <div className="d-flex align-items-center mt-3">
                  <img
                    src={vehicle.Driver.photo || user3}
                    alt={vehicle.Driver.username}
                    className="avatar-sm rounded-circle me-2"
                    onError={e => { e.target.src = user3 }}
                  />
                  <div>
                    <h6 className="mb-0">{vehicle.Driver.name}</h6>
                    <h6 className="mb-0">{vehicle.Driver.username}</h6>
                    <small className="text-muted">{vehicle.Driver.phone_number}</small>
                  </div>
                </div>
              </Link>
            ) : (
              <small>No Driver Assigned</small>
            )}
          </Col>
          <Col xl={4} md={4}>
            <div className="d-flex align-items-center mt-3">
              <img
                src={vehicle.photo || defaultVehicle}
                alt={vehicle.car_name}
                className="rounded-circle-10 me-2"
                style={{ width: "64px", height: "64px" }}
                onError={e => { e.target.src = defaultVehicle }}
              />
            </div>
            <br />
            <Link to={`/vehicles/${vehicle.id}`} className="btn btn-primary btn-sm">
              <i className="mdi mdi-arrow-right h5"></i>
            </Link>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default VehicleCard;
