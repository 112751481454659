export default function validatePhoneNumber(phone_number) {
  // Check for +251 format (13 digits)
  if (phone_number.startsWith('+251') && phone_number.length === 13) {
    const numberPart = phone_number.slice(4); // Get the part after '+251'
    const isValid = /^\d{9}$/.test(numberPart); // Check if it contains exactly 9 digits
    console.log(isValid);
    return isValid;
  }

  // Check for 0 format (10 digits)
  if (phone_number.startsWith('0') && phone_number.length === 10) {
    const isValid = /^\d{10}$/.test(phone_number); // Check if all characters are digits
    console.log(isValid);
    return isValid;
  }

  // If it doesn't match either format
  console.log(false);
  return false;
}
