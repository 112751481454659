import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from "reactstrap"
import { Link, Navigate } from "react-router-dom"
// Custom Scrollbar
import SimpleBar from "simplebar-react"

// import images
import servicesIcon1 from "../../assets/images/services-icon/01.png"
import servicesIcon2 from "../../assets/images/services-icon/02.png"
import servicesIcon3 from "../../assets/images/services-icon/03.png"
import servicesIcon4 from "../../assets/images/services-icon/04.png"




import "chartist/dist/scss/chartist.scss"
import MiniStatCard from "../../components/Common/Ministat"
//i18n
import { withTranslation } from "react-i18next"
import { data } from "pages/AllCharts/chartjs/linechart"

import LatestTranaction from "./LatestTranaction"
import TopCities from "./TopCities"
import StackedColumnChart from "./StackedColumnChart"
import SocialSource from "./SocialSource"
import { GetLoggedInUser } from "helpers/backend_helper"



const Hub = props => {
  const [menu, setMenu] = useState(false)
  const [user, setUser] = useState(GetLoggedInUser)
  const toggle = () => {
    setMenu(!menu)
  }

  document.title = "Dashboard | hearts - React Admin & Dashboard  "
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h6 className="page-title">HUB</h6>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    Control major actions here
                  </li>
                </ol>
              </Col>

              <Col md="4">
                <div className="float-end d-none d-md-block"></div>
              </Col>
            </Row>
          </div>

          <Row >
            <Col xl={4} md={6}>
              <MiniStatCard
                icon={servicesIcon1}
                title="Clients"
                changeDirection="up"
                bg_color="green"
                link="/clients"
              />
            </Col>
            <Col xl={4} md={6}>
              <MiniStatCard
                icon={servicesIcon2}
                title="Complaints"
                changeDirection="up"
                bg_color="green"

                link="/compaints"
              />
            </Col>
            <Col xl={4} md={6}>
              <MiniStatCard
                icon={servicesIcon4}
                title="Push Notifications"
                changeDirection="up"
                link="/notification"
              />
            </Col>
            <Col xl={4} md={6}>
              <MiniStatCard
                icon={servicesIcon3}
                title="Banners"
                changeDirection="up"
                bg_color="green"
                link="/banners"
              />
            </Col>
            <Col xl={4} md={6}>
              <MiniStatCard
                icon={servicesIcon3}
                title="App Information"
                changeDirection="up"
                bg_color="green"
                link="/app_info"
              />
            </Col>
          </Row>

          <Row>

            <Col >

              <LatestTranaction />
            </Col>



          </Row>

          <Row>
            <Col md="4">

              <StackedColumnChart />
            </Col>
            <Col md="4">

              <SocialSource />
            </Col>

            <Col md="4">

              <TopCities />
            </Col>


          </Row>



          {/* activity list / steps esc */}
        </Container>
      </div>
    </React.Fragment>
  )
}

Hub.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(Hub)
