import React, { useEffect, useState } from 'react';
import {
    Container,
    Row,
    Col,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
} from "reactstrap";
import { UpdateAppInformation, GetAppInformation } from '../../helpers/backend_helper';
import { toast } from "react-toastify";
import { LoadingButton, LoadingPage } from "pages/Utility/loading";

const LanguageSelector = ({ languages, selectedLanguage, onLanguageChange }) => {
    return (
        <div className="d-flex justify-content-between mb-3">
            {languages.map(lang => (
                <Button
                    key={lang.code}
                    color={selectedLanguage.code === lang.code ? 'primary' : 'light'}
                    onClick={() => onLanguageChange(lang)}
                    className="mr-3" // Increased margin for better spacing
                >
                    {lang.name}
                </Button>
            ))}
        </div>
    );
};

function AppInformation() {
    const [appInfo, setAppInfo] = useState({
        name: '',
        version: '',
        about: '',
        help_text: '',
        terms_of_service: '',
        privacy_policy: '',
        email: '',
        phone_number: '',
        address: '',
        terms_of_service_url: '',
        privacy_policy_url: '',
        language: 'en',
    });

    const [isEditing, setIsEditing] = useState(false);
    const [availableLanguages, setAvailableLanguages] = useState([{ name: 'English', code: 'en' }, { name: 'Amharic', code: 'am' }]);
    const [loading, setLoading] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState(availableLanguages[1]); // Default to first language

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const data = await GetAppInformation({ params: { language: selectedLanguage.code } });
                setAppInfo(data);
                // toast.success("App information loaded successfully.");
            } catch (error) {
                toast.error("Failed to load app information.");
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [selectedLanguage]); // Trigger fetch when selectedLanguage changes

    const handleChange = (e) => {
        const { name, value } = e.target;
        setAppInfo({
            ...appInfo,
            [name]: value,
        });
    };

    const handleLanguageChange = (newLanguage) => {
        setSelectedLanguage(newLanguage); // Update selected language
        setAppInfo(prevState => ({ ...prevState, language: newLanguage.code })); // Update language in appInfo
    };

    const handleSave = async () => {
        setLoading(true);
        try {
            await UpdateAppInformation(appInfo, { params: { language: selectedLanguage.code } });
            toast.success("App information saved successfully.");
            setIsEditing(false);
        } catch (error) {
            toast.error("Failed to save app information.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <h1>App Information</h1>
                            <p>This is the App Information page.</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <LanguageSelector
                                languages={availableLanguages}
                                selectedLanguage={selectedLanguage}
                                onLanguageChange={handleLanguageChange}
                            />
                            {loading && (
                                <div className="d-flex justify-content-center mb-3">
                                    <LoadingPage type="Circles" color="#00BFFF" height={50} width={50} />
                                </div>
                            )}
                            <Form>
                                <Row className='d-flex justify-content-start'>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Label for="name">Name</Label>
                                            <Input
                                                type="text"
                                                name="name"
                                                id="name"
                                                value={appInfo.name}
                                                onChange={handleChange}
                                                disabled={!isEditing}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Label for="version">Version</Label>
                                            <Input
                                                type="text"
                                                name="version"
                                                id="version"
                                                value={appInfo.version}
                                                onChange={handleChange}
                                                disabled={!isEditing}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <FormGroup>
                                    <Label for="about">About</Label>
                                    <Input
                                        type="textarea"
                                        style={{ height: '100px' }}
                                        name="about"
                                        id="about"
                                        value={appInfo.about}
                                        onChange={handleChange}
                                        disabled={!isEditing}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="help_text">Help Text</Label>
                                    <Input
                                        type="textarea"
                                        style={{ height: '100px' }}
                                        name="help_text"
                                        id="help_text"
                                        value={appInfo.help_text}
                                        onChange={handleChange}
                                        disabled={!isEditing}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="terms_of_service">Terms of Service</Label>
                                    <Input
                                        type="textarea"
                                        style={{ height: '100px' }}
                                        name="terms_of_service"
                                        id="terms_of_service"
                                        value={appInfo.terms_of_service}
                                        onChange={handleChange}
                                        disabled={!isEditing}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="privacy_policy">Privacy Policy</Label>
                                    <Input
                                        type="textarea"
                                        style={{ height: '100px' }}
                                        name="privacy_policy"
                                        id="privacy_policy"
                                        value={appInfo.privacy_policy}
                                        onChange={handleChange}
                                        disabled={!isEditing}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="email">Email</Label>
                                    <Input
                                        type="email"
                                        name="email"
                                        id="email"
                                        value={appInfo.email}
                                        onChange={handleChange}
                                        disabled={!isEditing}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="phone_number">Phone Number</Label>
                                    <Input
                                        type="text"
                                        name="phone_number"
                                        id="phone_number"
                                        value={appInfo.phone_number}
                                        onChange={handleChange}
                                        disabled={!isEditing}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="address">Address</Label>
                                    <Input
                                        type="text"
                                        name="address"
                                        id="address"
                                        value={appInfo.address}
                                        onChange={handleChange}
                                        disabled={!isEditing}
                                    />
                                </FormGroup>
                                <Row className='d-flex justify-content-start'>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Label for="terms_of_service_url">Terms of Service URL</Label>
                                            <Input
                                                type="url"
                                                name="terms_of_service_url"
                                                id="terms_of_service_url"
                                                value={appInfo.terms_of_service_url}
                                                onChange={handleChange}
                                                disabled={!isEditing}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Label for="privacy_policy_url">Privacy Policy URL</Label>
                                            <Input
                                                type="url"
                                                name="privacy_policy_url"
                                                id="privacy_policy_url"
                                                value={appInfo.privacy_policy_url}
                                                onChange={handleChange}
                                                disabled={!isEditing}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm="12" className="d-flex justify-content-end">
                                        {isEditing ? (
                                            <>
                                                <Button color="primary" onClick={handleSave} disabled={loading}>
                                                    {loading ? 'Saving...' : 'Save'}
                                                </Button>
                                                <Button color="secondary" onClick={() => setIsEditing(false)} className="ml-2">
                                                    Cancel
                                                </Button>
                                            </>
                                        ) : (
                                            <Button color="primary" onClick={() => setIsEditing(true)}>
                                                Edit
                                            </Button>
                                        )}
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default AppInformation;