// import React, { Component } from "react"
// import {
//   Carousel,
//   CarouselItem,
//   CarouselControl,
//   CarouselIndicators,
// } from "reactstrap"

// // Carousel images
// import img1 from "../../../assets/images/small/img-1.jpg"
// import img2 from "../../../assets/images/small/img-2.jpg"
// import img3 from "../../../assets/images/small/img-3.jpg"

// const items = [
//   {
//     src: img1,
//     altText: "Slide 1",
//     caption: "Slide 1",
//   },
//   {
//     src: img2,
//     altText: "Slide 2",
//     caption: "Slide 2",
//   },
//   {
//     src: img3,
//     altText: "Slide 3",
//     caption: "Slide 3",
//   },
// ]

// class Slidewithfade extends Component {
//   constructor(props) {
//     super(props)
//     this.state = { activeIndex: 0 }
//     this.next = this.next.bind(this)
//     this.previous = this.previous.bind(this)
//     this.goToIndex = this.goToIndex.bind(this)
//     this.onExiting = this.onExiting.bind(this)
//     this.onExited = this.onExited.bind(this)
//   }

//   onExiting() {
//     this.animating = true
//   }

//   onExited() {
//     this.animating = false
//   }

//   next() {
//     if (this.animating) return
//     const nextIndex =
//       this.state.activeIndex === items.length - 1
//         ? 0
//         : this.state.activeIndex + 1
//     this.setState({ activeIndex: nextIndex })
//   }

//   previous() {
//     if (this.animating) return
//     const nextIndex =
//       this.state.activeIndex === 0
//         ? items.length - 1
//         : this.state.activeIndex - 1
//     this.setState({ activeIndex: nextIndex })
//   }

//   goToIndex(newIndex) {
//     if (this.animating) return
//     this.setState({ activeIndex: newIndex })
//   }

//   render() {
//     const { activeIndex } = this.state

//     const slides = items.map(item => {
//       return (
//         <CarouselItem
//           onExiting={this.onExiting}
//           onExited={this.onExited}
//           key={item.src}
//         >
//           <img
//             src={item.src}
//             className="d-block img-fluid"
//             alt={item.altText}
//           />
//         </CarouselItem>
//       )
//     })

//     return (
//       <React.Fragment>
//         <Carousel
//           activeIndex={activeIndex}
//           fade={true}
//           next={this.next}
//           previous={this.previous}
//         >
//           <CarouselIndicators
//             items={items}
//             activeIndex={activeIndex}
//             onClickHandler={this.goToIndex}
//           />
//           {slides}
//           <CarouselControl
//             direction="prev"
//             directionText="Previous"
//             onClickHandler={this.previous}
//           />
//           <CarouselControl
//             direction="next"
//             directionText="Next"
//             onClickHandler={this.next}
//           />
//         </Carousel>
//       </React.Fragment>
//     )
//   }
// }

// export default Slidewithfade

import React, { Component } from "react"
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
} from "reactstrap"

// Default images
const defaultImg1 =
  "https://firebasestorage.googleapis.com/v0/b/hearts-36da3.appspot.com/o/banner_images%2Fbanner2.jpg?alt=media&token=af733d24-8c22-4e39-9c06-af4b398831ef"
const defaultImg2 =
  "https://firebasestorage.googleapis.com/v0/b/hearts-36da3.appspot.com/o/banner_images%2Fbanner3.jpg?alt=media&token=863f060e-ee6a-4947-a2ff-901294083174"
const defaultImg3 =
  "https://firebasestorage.googleapis.com/v0/b/hearts-36da3.appspot.com/o/banner_images%2Fbanner4.jpg?alt=media&token=6430ed68-b839-4a67-b6a9-adfb48808399"

class Slidewithfade extends Component {
  constructor(props) {
    super(props)
    this.state = { activeIndex: 0 }
    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
    this.goToIndex = this.goToIndex.bind(this)
    this.onExiting = this.onExiting.bind(this)
    this.onExited = this.onExited.bind(this)
  }

  onExiting() {
    this.animating = true
  }

  onExited() {
    this.animating = false
  }

  next() {
    if (this.animating) return
    const nextIndex =
      this.state.activeIndex === this.items.length - 1
        ? 0
        : this.state.activeIndex + 1
    this.setState({ activeIndex: nextIndex })
  }

  previous() {
    if (this.animating) return
    const nextIndex =
      this.state.activeIndex === 0
        ? this.items.length - 1
        : this.state.activeIndex - 1
    this.setState({ activeIndex: nextIndex })
  }

  goToIndex(newIndex) {
    if (this.animating) return
    this.setState({ activeIndex: newIndex })
  }

  render() {
    // Use props images if available; otherwise, fallback to default images
    const defaultItems = [
      {
        src: defaultImg1,
        altText: "Default Slide 1",
        caption: "Default Slide 1",
      },
      {
        src: defaultImg2,
        altText: "Default Slide 2",
        caption: "Default Slide 2",
      },
      {
        src: defaultImg3,
        altText: "Default Slide 3",
        caption: "Default Slide 3",
      },
    ]

    // Use provided images or fallback to default images
    this.items = this.props.images?.length ? this.props.images : defaultItems

    const { activeIndex } = this.state

    const slides = this.items.map((item, index) => {
      return (
        <CarouselItem
          onExiting={this.onExiting}
          onExited={this.onExited}
          key={index}
        >
          <img
            src={item.src}
            className="d-block img-fluid"
            alt={item.altText}
          />
        </CarouselItem>
      )
    })

    return (
      <React.Fragment>
        <Carousel
          activeIndex={activeIndex}
          fade={true}
          next={this.next}
          previous={this.previous}
        >
          <CarouselIndicators
            items={this.items}
            activeIndex={activeIndex}
            onClickHandler={this.goToIndex}
          />
          {slides}
          <CarouselControl
            direction="prev"
            directionText="Previous"
            onClickHandler={this.previous}
          />
          <CarouselControl
            direction="next"
            directionText="Next"
            onClickHandler={this.next}
          />
        </Carousel>
      </React.Fragment>
    )
  }
}

export default Slidewithfade
