import React from 'react'
import { RotatingLines } from 'react-loader-spinner'

function LoadingPage() {
  return (
    <div style={{
        display: 'flex', justifyContent: 'center', 
        alignItems: 'center', height: '100vh'}} >  
                              <RotatingLines
                              visible={true}
                              height="96"
                              width="96"
                              color="#111"
                              strokeWidth="3"
                              animationDuration="0.75"
                              ariaLabel="rotating-lines-loading"
                              wrapperStyle={{}}
                              wrapperClass=""
                              />
      </div>
  )
}

function LoadingButton() {
    return (
        <RotatingLines
        visible={true}
        height="44"
        width="44"
        color="#111"
        strokeWidth="3"
        animationDuration="0.75"
        ariaLabel="rotating-lines-loading"
        wrapperStyle={{}}
        wrapperClass=""
        />
    )
  }

export  {LoadingPage, LoadingButton}