import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Container,
} from "reactstrap"

// Carousel
import Slide from "../Ui/CarouselTypes/slide"
import Slidewithcontrol from "../Ui/CarouselTypes/slidewithcontrol"
import Slidewithindicator from "../Ui/CarouselTypes/slidewithindicator"
import Slidewithcaption from "../Ui/CarouselTypes/slidewithcaption"
import Slidewithfade from "../Ui/CarouselTypes/slidewithfade"
import { GetBanners, GetLoggedInUser } from "../../helpers/backend_helper"

function BannerCarosell() {
  const [Banners, setBanners] = useState()
  useEffect(() => {
    const authUser =GetLoggedInUser()
    const getBanners = async () => {
      try {
        const response = await GetBanners({
          params: { providerId: authUser.provider_id },
        }) // Replace with your actual fetch function
        // console.log("response", response)
        const mappedBanners = response.map(banner => ({
          src: banner.image || defaultBanner.src, // Use default if image is missing
          altText: banner.title || "Banner", // Use title or default alt text
          caption: banner.title || "", // Use title as caption if available
        }))
        setBanners(mappedBanners)
      } catch (error) {
        console.error("Error fetching banners:", error)
        // Handle errors appropriately (e.g., display an error message)
      }
    }

    getBanners()
  }, [])
  return (
    <Container fluid>
      <Row className="relative  d-flex align-items-center justify-content-center mb-4">
        <Row>
          <Slidewithfade images={Banners} />
        </Row>
      </Row>
    </Container>
  )
}

export default BannerCarosell
