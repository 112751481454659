import React from "react"
import { Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"
const MiniStatCard = ({
  icon,
  title,
  value,
  change,
  changeDirection,
  link,
  bg_color,
  positive,
}) => {
  return (
    <Link to={link ?? "#"} className="text-white-50">
      <Card
        className={`mini-stat text-white  ${bg_color ? "" : "bg-primary"}`}
        style={{
          maxHeight: 160,
          flex: 1,
          height: "100%",
          backgroundColor: bg_color,
        }}
      >
        <CardBody>
          <div className="mb-4">
            <div className="float-start mini-stat-img me-4">
              <img src={icon} alt="" />
            </div>
            <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
              {title}
            </h5>
            <h4 className="fw-medium font-size-24">
              {value}{" "}
              {changeDirection && (
                <i
                  className={`mdi mdi-arrow-BR.{changeDirection} text-danger ms-2`}
                ></i>
              )}
            </h4>
            {change && (
              <div className={`mini-stat-label ${positive ? "bg-danger" :"bg-success "}`}>
                <p className="mb-0">{change}</p>
              </div>
            )}
          </div>
          <div className="pt-2">
            <div className="float-end">
              {link && (
                <Link to={link} className="text-white-50">
                  <i className="mdi mdi-arrow-right h5"></i>
                </Link>
              )}
            </div>
            {/* <p className="text-white-50 mb-0 mt-1">From start till now</p> */}
          </div>
        </CardBody>
      </Card>
    </Link>
  )
}

export default MiniStatCard
