
import React, { useState, useEffect } from "react"
import {
  Container,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import Dropzone from "react-dropzone"
import { post, del, get, put } from "../../helpers/api_helper"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useParams, useNavigate } from "react-router-dom"
import user3 from "../../assets/images/users/user-3.jpg"
import { toast } from "react-toastify"
import CreateAmbulanceTypeModal from "../providers/CreateAmbulanceTypeModal"

import { ref, uploadBytes, getDownloadURL } from "firebase/storage"
import { storage } from "../../helpers/firebase_storage.js" // Adjust the path as needed
import { GetLoggedInUser, GetProviderById, UpdateProvider } from "helpers/backend_helper"
import { LoadingButton, LoadingPage } from "pages/Utility/loading"
import { set } from "lodash"

const MyProvider = props => {
  const [user] = useState(GetLoggedInUser)
  const [id, setId] = useState(user?.provider_id)
  const navigate = useNavigate()
  const [providerData, setProviderData] = useState(null)
  const [isEditing, setIsEditing] = useState(false)
  const [editProviderDetail, setEditProviderDetail] = useState(false)
  const [editedProviderData, setEditedProviderData] = useState(null)
  const [isAmbulanceTypeEditing, setIsAmbulanceTypeEditing] = useState(false)
  const [selectedAmbulanceType, setSelectedAmbulanceType] = useState(null)
  const [isClicked, setIsClicked] = useState(false)
  const [isdeleting, setIsDeleting] = useState(false)

  useEffect(() => {
    const fetchManagerData = async () => {
      try {
        const data = await GetProviderById(id)
        setProviderData(data)
        setEditedProviderData(data)
      } catch (error) {
        console.error("Error fetching providers data:", error)
        toast.error("Error fetching providers data")
      }
    }
    if (id != null) {
      fetchManagerData()
    }
  }, [id])

  const handleAcceptedFiles = async acceptedFiles => {
    if (acceptedFiles.length > 0) {
      try{ 
        const file = acceptedFiles[0]
          var formData = new FormData();
            formData.append("files", file)
    
          // Log the contents of the FormData
          for (const [key, value] of formData.entries()) {
          }
          
    
          var response = await post("/upload", ...formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          const fileUrl = response.data.fileUrls[file.name];
          setEditedProviderData((prevData) => ({
            ...prevData,
            photo: fileUrl, 
          }));
    
          } catch (error) {
            toast.error("Error uploading file");
          }
    }
  }

  const handleEdit = () => setIsEditing(true)
  const handleSave = async () => {
    try {
      setIsClicked(true)
      const  {AmbulanceTypes, Drivers, Managers
        , PaymentMethods, Requests
        , ...rest
      } = editedProviderData
      setProviderData(editedProviderData)
      setEditProviderDetail(false)
      console.log(rest)
      await UpdateProvider( id, rest)
      toast.success("updated")
      setIsClicked(false)
    } catch (error) {
      setIsClicked(false)
      toast.error(error)
    }
  }
  const handleCancel = () => {
    setEditedProviderData(providerData)
    setEditProviderDetail(false)
  }
  const handleAmbulanceTypeEdit = ambulanceType => {
    setSelectedAmbulanceType(ambulanceType)
    setIsAmbulanceTypeEditing(true)
  }

  const handleAmbulanceTypeSave =  async (ambulanceType) => {
    setIsClicked(true)
    try {
      setSelectedAmbulanceType(ambulanceType.id)
      setIsAmbulanceTypeEditing(false)
      await put(`/ambulance_types/${ambulanceType.id}`, ambulanceType)
      toast.success("updated successfully")
      setIsClicked(false)
    } catch (error) {
      setIsClicked(false)
      toast.error("couldn't update ambulance type")
    }
  }

  const handleAmbulanceTypeDelete = async ambulanceType => {
    setIsDeleting(true)
    try {
      setSelectedAmbulanceType(ambulanceType)
      setIsAmbulanceTypeEditing(false)
      await del(`/ambulance_types/${ambulanceType.id}`, {})
      toast.success("ambulance type deleted successfully")
      setIsDeleting(false)
    } catch (error) {
      setIsDeleting(false)
      toast.error("couldn't delete ambulances type")
    }
  }

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalType, setModalType] = useState("")

  const toggleModal = type => {
    setModalType(type)
    setIsModalOpen(!isModalOpen)
  }

  if (!providerData) return <LoadingPage />
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col md="7">
              <Breadcrumbs
                maintitle="hearts"
                title="Provider"
                // breadcrumbItem={id}
              />
            </Col>
            <Col md="5" className="align-self-end" />
          </Row>

          <div>
            <h2>Provider Details</h2>
            <Form>
              <Row>
                <Col>
                  <FormGroup>
                    <Label for="image">Profile image</Label>
                    {editProviderDetail ? (
                      <Dropzone onDrop={handleAcceptedFiles}>
                        {({ getRootProps, getInputProps }) => (
                          <div
                            {...getRootProps()}
                            style={{
                              backgroundImage: `url(${
                                editedProviderData?.photo ||
                                providerData.photo ||
                                user3
                              })`,
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                              height: "100px",
                              width: "100px",
                              borderRadius: "50%",
                              border: "2px solid #ccc",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <input {...getInputProps()} />
                            {editProviderDetail && (
                              <div
                                style={{
                                  position: "relative",
                                  top: "50px",
                                  left: "50px",
                                  background: "rgba(255, 255, 255, 0.7)",
                                  borderRadius: "50%",
                                  padding: "5px",
                                }}
                              >
                                <i className="mdi mdi-cloud-upload display-6 text-muted"></i>
                              </div>
                            )}
                          </div>
                        )}
                      </Dropzone>
                    ) : (
                      <div
                        style={{
                          backgroundImage: `url(${
                            editedProviderData?.photo ||
                            providerData.photo ||
                            user3
                          })`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          height: "100px",
                          width: "100px",
                          borderRadius: "50%",
                          border: "2px solid #ccc",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      />
                    )}
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label for="name">Name:</Label>
                    <Input
                      type="text"
                      id="name"
                      value={editedProviderData?.name || ""}
                      onChange={e =>
                        setEditedProviderData({
                          ...editedProviderData,
                          name: e.target.value,
                        })
                      }
                      disabled={!editProviderDetail}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="email">Email:</Label>
                    <Input
                      type="email"
                      id="email"
                      value={editedProviderData?.email || ""}
                      onChange={e =>
                        setEditedProviderData({
                          ...editedProviderData,
                          email: e.target.value,
                        })
                      }
                      disabled={!editProviderDetail}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label for="phone_number">Phone Number:</Label>
                    <Input
                      type="tel"
                      id="phone_number"
                      value={editedProviderData?.phone_number || ""}
                      onChange={e =>
                        setEditedProviderData({
                          ...editedProviderData,
                          phone_number: e.target.value,
                        })
                      }
                      disabled={!editProviderDetail}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup check className="form-check form-switch mb-3">
                    <Label
                      check
                      className="form-check-label"
                      htmlFor="is_active"
                    >
                      Is Active
                    </Label>
                    <Input
                      className="form-check-input"
                      type="checkbox"
                      id="is_active"
                      checked={editedProviderData?.is_active || false}
                      disabled
                    />
                  </FormGroup>
                </Col>
              </Row>

              {editProviderDetail ? (
                <Row>
                  <Col sm="1">
                  {!isClicked?(<Button color="primary" onClick={handleSave}>
                        Save
                      </Button> ):(<div style={{ display: "flex", justifyContent: "right", width: "100%" }}>
                    <LoadingButton />
                  </div>)}
                  </Col>
                  <Col sm="1">
                    <Button color="warning" onClick={handleCancel}>
                      Cancel
                    </Button>
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col sm="1">
                    <Button
                      color="primary"
                      onClick={() => setEditProviderDetail(true)}
                    >
                      Edit
                    </Button>
                  </Col>
                </Row>
              )}
            </Form>
          </div>

          <div>
            <br />
            <h2>Ambulance Types</h2>
            {providerData.AmbulanceTypes &&
              providerData.AmbulanceTypes.map(ambulanceType => (
                <Card key={ambulanceType.id}>
                  <CardBody>
                    <h3>{ambulanceType.name}</h3>
                    <Form>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label for="farePerKm">Fare Per KM:</Label>
                            <Input
                              type="number"
                              id="farePerKm"
                              value={
                                selectedAmbulanceType?.id === ambulanceType.id
                                  ? selectedAmbulanceType.farePerKm
                                  : ambulanceType.farePerKm
                              }
                              onChange={e =>
                                setSelectedAmbulanceType({
                                  ...selectedAmbulanceType,
                                  farePerKm: parseFloat(e.target.value),
                                })
                              }
                              disabled={
                                !(
                                  isAmbulanceTypeEditing &&
                                  selectedAmbulanceType?.id === ambulanceType.id
                                )
                              }
                            />
                          </FormGroup>

                          <FormGroup
                            check
                            className="form-check form-switch mb-3"
                          >
                            <Label
                              check
                              className="form-check-label"
                              htmlFor="is_active"
                            >
                              Is Active
                            </Label>
                            <Input
                              className="form-check-input"
                              type="checkbox"
                              id="is_active"
                              checked={
                                selectedAmbulanceType?.id === ambulanceType.id
                                  ? selectedAmbulanceType.is_active
                                  : ambulanceType.is_active
                              }
                              onClick={e => {
                                // console.log("clicked")
                                if (
                                  selectedAmbulanceType?.id === ambulanceType.id
                                ) {
                                  setSelectedAmbulanceType({
                                    ...selectedAmbulanceType,
                                    is_active: e.target.checked,
                                  })
                                }
                              }}
                              onChange={e => {
                                // console.log("changed")
                                if (
                                  selectedAmbulanceType?.id === ambulanceType.id
                                ) {
                                  setSelectedAmbulanceType({
                                    ...selectedAmbulanceType,
                                    is_active: e.target.checked,
                                  })
                                }
                              }}
                              disabled={
                                !(
                                  isAmbulanceTypeEditing &&
                                  selectedAmbulanceType?.id === ambulanceType.id
                                )
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label for="fixedPrice">Fixed Price:</Label>
                            <Input
                              type="number"
                              id="fixedPrice"
                              value={
                                selectedAmbulanceType?.id === ambulanceType.id
                                  ? selectedAmbulanceType.fixedPrice
                                  : ambulanceType.fixedPrice
                              }
                              onChange={e =>
                                setSelectedAmbulanceType({
                                  ...selectedAmbulanceType,
                                  fixedPrice: parseFloat(e.target.value),
                                })
                              }
                              disabled={
                                !(
                                  isAmbulanceTypeEditing &&
                                  selectedAmbulanceType?.id === ambulanceType.id
                                )
                              }
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      {isAmbulanceTypeEditing &&
                      selectedAmbulanceType?.id === ambulanceType.id ? (
                        <Row>
                          <Col sm="1">
                          {!isClicked? (<Button
                              color="primary"
                              onClick={() =>
                                handleAmbulanceTypeSave(selectedAmbulanceType)
                              }
                            >
                              Save
                            </Button>):(<div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                    <LoadingButton />
                  </div>)}
                          </Col>
                          <Col sm="1">
                          {!isdeleting?( <Button
                              color="danger"
                              onClick={() =>
                                handleAmbulanceTypeDelete(selectedAmbulanceType)
                              }
                            >
                              Delete                             </Button>):(<div style={{ display: "flex", justifyContent: "right", width: "100%" }}>
                    <LoadingButton />
                  </div>)}

                          </Col>
                          <Col sm="1">
                            <Button
                              color="danger"
                              onClick={() => setIsAmbulanceTypeEditing(false)}
                            >
                              Cancel
                            </Button>
                          </Col>
                        </Row>
                      ) : (
                        <Row>
                          <Col sm="1">
                            <Button
                              color="primary"
                              onClick={() =>
                                handleAmbulanceTypeEdit(ambulanceType)
                              }
                            >
                              Edit
                            </Button>
                          </Col>
                        </Row>
                      )}
                    </Form>
                  </CardBody>
                </Card>
              ))}

            <div className="my-4 text-center">
              <Button
                color="primary"
                onClick={() => toggleModal("scroll")}
                className="m-0"
              >
                Create New Ambulance Type
              </Button>
            </div>
            <CreateAmbulanceTypeModal
              isModalOpen={isModalOpen}
              toggleModal={toggleModal}
              modalType={modalType}
            />
            {/* <Button
              color="primary"
              onClick={() => CreateAmbulanceType({id:id})}
            >
              Create New Ambulance Type
            </Button>  */}
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default MyProvider
